import React from "react";
import Connector from "./Connector";
import "./App.css";

function App(props) {
  const { companyId, justShowScreens } = props;
  let { hostName } = props;
  if (hostName && hostName.startsWith("www.")) hostName = hostName.slice(4);
  if (hostName && hostName.endsWith("/")) hostName = hostName.slice(0, -1);

  return (
    <div className="App sc_connector_react">
      <Connector companyId={companyId} hostName={hostName} justShowScreens={justShowScreens} />
    </div>
  );
}

export default App;
