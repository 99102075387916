const WarningLogo = () => (
  <svg
    width="30"
    height="20"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_33_4183)">
      <path
        d="M4.28733 1.6083L0.758159 7.49996C0.685396 7.62597 0.646895 7.76884 0.646488 7.91435C0.64608 8.05985 0.68378 8.20293 0.755837 8.32935C0.827893 8.45576 0.931795 8.56111 1.0572 8.6349C1.18261 8.70869 1.32516 8.74836 1.47066 8.74996H8.52899C8.67449 8.74836 8.81704 8.70869 8.94245 8.6349C9.06786 8.56111 9.17176 8.45576 9.24381 8.32935C9.31587 8.20293 9.35357 8.05985 9.35316 7.91435C9.35276 7.76884 9.31426 7.62597 9.24149 7.49996L5.71233 1.6083C5.63805 1.48584 5.53346 1.3846 5.40866 1.31433C5.28385 1.24407 5.14305 1.20715 4.99983 1.20715C4.8566 1.20715 4.7158 1.24407 4.59099 1.31433C4.46619 1.3846 4.36161 1.48584 4.28733 1.6083V1.6083Z"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 3.75V5.41667"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5 7.08337H5.00417"
        stroke="black"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_33_4183">
        <rect width="10" height="10" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default WarningLogo;
