import {useEffect, useContext, useState} from "react";
import LauncherAndWidget from "./components/Launcher/index";
import Modal from "react-modal";
import {RouterContext} from "./contexts/routerContext";
import ResolveScreen, {ScreenTypes} from "./components/screens/resolver";
import Header from "./components/Header";
import {ConnectorContext} from "./contexts/connectorContext";
import {getConnectorForCompany} from "./api/connector";

interface ConnectorProps {
    hostName: string;
    companyId: string;
    justShowScreens?: string;
}

Modal.setAppElement("#widget-stake-trunk");

let getConnector = () => {
};

const Connector: React.FC<ConnectorProps> = (props) => {
    const {companyId, hostName, justShowScreens} = props;
    const connectorContext = useContext(ConnectorContext) as any;
    const routerContext = useContext(RouterContext);
    const [initialized, setInitialized] = useState(false);
    const [connectorScreens, setConnectorScreens] = useState();
    const [show, setShow] = useState(false);

    getConnector = () => {
        getConnectorForCompany(companyId, hostName)
            .then((data) => {
                const conn = data as any;
                setConnectorScreens(conn);

                connectorContext.validate(conn);
                connectorContext.setCssColors(conn);

                if (conn.connector_status === 0) {
                    connectorContext.setConnector(conn);
                }

                if (justShowScreens === "true") {
                    addLoginScreen(conn);
                    if (conn.connector_status === 0) {
                        connectorContext.setConnector(conn);
                    }

                    routerContext.setDefaultScreens(conn.connector_screens);
                    routerContext.setScreens(conn.connector_screens);
                }


                window?.top?.postMessage(
                    {
                        message: "companyLoaded",
                        data: {
                            zIndex: conn.connector_launcher.launcher_zindex || 10000,
                            isConnectorActive: conn.connector_status,
                            offset: conn.connector_launcher.launcher_offset,
                        },
                    },
                    "*"
                );

                const openDelay = conn.connector_launcher.launcher_delay ?? 0;
                setTimeout(() => {
                    setInitialized(true);
                }, openDelay);
            })
            .catch(() => {
                window?.top?.postMessage(
                    {
                        message: "removeIframe",
                    },
                    "*"
                );
            });
        //connectorContext.setConnector(mockConnector); //conn);
        //routerContext.setDefaultScreens(mockConnector.screens);
        //routerContext.setScreens(mockConnector.screens);
        //setInitialized(true);
    }

    const addLoginScreen = (conn: any) => {
        if (conn.connector_version === 'Connector+' && conn.company_stock_symbol) {
            conn.connector_screens.push({
                screen_id: "id_login",
                screen_name: "Login",
                screen_type: "Login",
                screen_status: 1,
                screen_sequence_number: conn.connector_screens.length,
                screen_data: "{}",
            });
        }
        return conn;
    }

    const setContactScreens = () => {
        let screens: any = connectorScreens;
        addLoginScreen(screens);
        if (screens.connector_status === 0) {
            connectorContext.setConnector(screens);
        }

        routerContext.setDefaultScreens(screens.connector_screens);
        routerContext.setScreens(screens.connector_screens);
    };

    const setTradeScreens = () => {
        let screens: any = connectorScreens;
        screens = {...screens, ...{connector_screens: []}};
        addLoginScreen(screens);
        if (screens.connector_status === 0) {
            connectorContext.setConnector(screens);
        }

        routerContext.setDefaultScreens(screens.connector_screens);
        routerContext.setScreens(screens.connector_screens);
    };


    useEffect(() => {
        getConnector();

        if (justShowScreens === "true") {
            // set background color to white
            document.body.style.backgroundImage = "url('Background-Connector.png')";
            window.top.postMessage({message: "modalOpen"}, "*");
            // open(Router, {}, opts)
            setTimeout(() => {
                setShow(true);
            }, 100);
        }
    }, []);

    // add umami analytics
    useEffect(() => {
        const script = document.createElement('script');

        script.src = "https://umami.ace.moe/script.js";
        script.async = true;
        script.setAttribute('data-website-id', "bc4af747-9379-4559-a9c2-6cd4b4aeb18b");

        if (show) {
            document.body.appendChild(script);
        } else {
            document.querySelector("script[src='https://umami.ace.moe/script.js']")?.remove();
        }

        // Cleanup function
        return () => {
            document.querySelector("script[src='https://umami.ace.moe/script.js']")?.remove();
        };
    }, [show]); // This effect runs every time the 'show' state changes


    function closeModal() {
        if (window.top !== null)
            window.top.postMessage({message: "modalClose"}, "*");
        setShow(false);
    }

    if (!initialized) return <></>;

    return (
        <div
            style={{
                bottom: 0,
                right: 0,
                position: "absolute",
                maxHeight: "100vh",
            }}
        >
            <LauncherAndWidget
                // if justShowScreens is true, then we don't want to show the launcher
                showLauncher={justShowScreens !== "true"}
                headerText={connectorContext.connector.connector_launcher.launcher_header_text}
                buttonText={connectorContext.connector.connector_launcher.launcher_button_text}
                stockSymbol={connectorContext.connector.company_stock_symbol}
                onClick={() => {
                    window?.top?.postMessage({message: "modalOpen"}, "*");
                    setTimeout(() => {
                        setContactScreens();
                        setShow(true);
                    }, 100);
                }}
                onTradeClick={() => {
                    window?.top?.postMessage({message: "modalOpen"}, "*");
                    setTimeout(() => {
                        setTradeScreens();
                        setShow(true);
                        //routerContext.goSkip();
                    }, 100);
                }}
            />

            <Modal
                isOpen={show}
                onRequestClose={closeModal}
                className="modal sc_connector_react"
                contentLabel="Example Modal"
                overlayClassName="overlay"
                shouldCloseOnOverlayClick={!justShowScreens}
                shouldCloseOnEsc={!justShowScreens}
            >
                <Header onClose={closeModal}/>
                <div className="modalContent">
                    {/*{console.log(routerContext.state)}*/}
                    <ResolveScreen
                        onClose={closeModal}
                        type={routerContext.state.screenType as ScreenTypes}
                        language={connectorContext.connector.connector_lang as any}
                        screenProps={{content: routerContext.state.screenContent as any}}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default Connector;
export {getConnector};