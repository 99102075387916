import { position1 } from "./mocks/positions";

export const getPositionsForUserAndSymbol = (
  userId: string,
  symbol: string
) => {
  return position1;
};

export default { getPositionsForUserAndSymbol };
