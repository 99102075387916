import styles from "./styles.module.scss";

const GenericBanner = (props: GenericBannerProps) => {
  const { companyLogoUrl, children } = props;

  return (
    <>
      <br />
      <div style={{ textAlign: "center" }}>
        {companyLogoUrl && (
          <img
            src={companyLogoUrl}
            alt="companyImage"
            className={styles.companyImage}
          />
        )}
      </div>
      <div className={styles.text}>
        {children}
        {/* <slot name="text" />
        <br />
        <slot name="subText" className={styles.subText} /> */}
      </div>
    </>
  );
};

interface GenericBannerProps {
  companyLogoUrl?: string;
  children?: JSX.Element[];
}

export default GenericBanner;
