import Layout from "../../Layouts/Layout/index";
import Contact from "./Contact/index";
import Button from "../../Button/index";
import ProgressBar from "../../ProgressBar/index";
import Newspaper from "../../icons/Newspaper/index";

import styles from "./styles.module.scss";
import { useContext } from "react";
import { RouterContext } from "../../../contexts/routerContext";
import { ConnectorContext } from "../../../contexts/connectorContext";

const CompanyMessageFr: React.FC<CompanyMessageProps> = (props) => {
  const routerContext = useContext(RouterContext);
  const connectorContext = useContext(ConnectorContext);
  const { content } = props;
  const { faceOfCompany } = connectorContext?.connector as Connector;

  return (
    <Layout>
      <Layout.Top>
        <section className={styles.imageAndMessage}>
          <h1>{content.title}</h1>
        </section>
      </Layout.Top>

      <Layout.Middle>
        <section className={styles.offering}>
          <p className={styles.message}>{content.body}</p>

          <div className={styles.details}>
            <Contact
              photoUrl={faceOfCompany.company_contact_avatar}
              name={`${faceOfCompany.company_contact_first_name} ${faceOfCompany.company_contact_last_name}`}
              subtitle={faceOfCompany.company_contact_position}
              pressReleaseUrl={content.pressReleaseLink}
            />
            <div className={styles.pressRelease}>
              {content != null &&
                content.pressReleaseLink != null &&
                content.pressReleaseLink.length > 0 && (
                  <>
                    <Newspaper />
                    <a
                      href={content.pressReleaseLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <span className="text">
                        {content.pressReleaseText || "Press Release"}
                      </span>
                    </a>
                  </>
                )}
            </div>
          </div>
        </section>
      </Layout.Middle>

      <Layout.Bottom>
        <Button onClick={routerContext.goNext}>Continuer</Button>
        <br />
        <br />
        <ProgressBar
          currentStep={routerContext.state.screenIndex}
          totalSteps={routerContext.state.totalScreenCount}
        />
      </Layout.Bottom>
    </Layout>
  );
};

interface CompanyMessageContent {
  title: string;
  body: string;
  pressReleaseLink: string;
  pressReleaseText: string;
}

export interface CompanyMessageProps {
  content: CompanyMessageContent;
  company: any;
  faceOfCompany: any;
}

export default CompanyMessageFr;
