import { useState, useContext } from "react";
import Layout from "../../Layouts/Layout/index";
import Button from "../../Button";
import ProgressBar from "../../ProgressBar";
import { AreaChart, Area, ResponsiveContainer, YAxis } from "recharts";
import styles from "./styles.module.scss";
import { RouterContext } from "../../../contexts/routerContext";
import { SubmissionContext } from "../../../contexts/submissionContext";
import chartData from "../../../mocks/chartData";
import { getPositionsForUserAndSymbol } from "../../../api/sharechest";

const SharesOwned: React.FC<any> = (props) => {
  const isLoading = useState(true);
  const routerContext = useContext(RouterContext);
  const submissionContext = useContext(SubmissionContext);

  let position = getPositionsForUserAndSymbol("123", "GME");
  const changeInPrice = position.current_price - position.lastday_price;

  const investmentType =
    // @ts-ignore
    submissionContext?.submission?.data?.interestTypes || "Investing";

  return (
    <Layout>
      <Layout.Top>
        <h1>Shares You Own</h1>
      </Layout.Top>
      <Layout.Middle>
        <section className={styles.container}>
          <div className={styles.chart}>
            <ResponsiveContainer width="100%" maxHeight={250}>
              <AreaChart
                height={200}
                data={chartData}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              >
                {/* <YAxis
                  tickLine={false}
                  domain={["dataMine", "dataMax"]}
                  width={2}
                  mirror
                  tickFormatter={(x) => `$${x}`}
                  orientation="right"
                  minTickGap={8}
                /> */}
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="10%" stopColor="#7871F4" stopOpacity={1} />
                    <stop offset="45%" stopColor="#7871F4" stopOpacity={0.5} />
                    <stop offset="55%" stopColor="#7871F4" stopOpacity={0.3} />
                    <stop offset="75%" stopColor="#7871F4" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <Area
                  type="monotone"
                  dataKey="uv"
                  stroke="#FFF"
                  fill="url(#colorUv)"
                />
              </AreaChart>
            </ResponsiveContainer>
            <div className={styles.accountStats}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    textAlign: "left",
                    fontSize: ".8em",
                    marginBottom: "4px",
                  }}
                >
                  <strong className={styles.ticker}>{position.symbol}</strong>
                  <strong>
                    {changeInPrice < 0 ? "Down" : "Up"} {changeInPrice} (
                    {position.change_today * 100}%) past day
                  </strong>
                </div>
                <div className={styles.keyValues} style={{ textAlign: "left" }}>
                  Apple
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>
                  <p>total shares</p>
                  <p className={styles.keyValues}>{position.qty}</p>
                </div>
                <div>
                  <p>total worth</p>
                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <p className={styles.keyValues}>{position.market_value}</p>
                    <p style={{ marginBottom: "4px" }}>USD</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout.Middle>

      <Layout.Bottom>
        <Button onClick={routerContext.goNext}>
          <>{investmentType === "Investing" ? "Buy More" : "Sell"} Shares</>
        </Button>
        <br />
        <br />
        <ProgressBar
          currentStep={routerContext.state.screenIndex}
          totalSteps={routerContext.state.totalScreenCount}
        />
      </Layout.Bottom>
    </Layout>
  );
};

// interface Bar {
//   name: string;
//   uv: number;
//   amt: number;
// }

// interface SharesOwned {
//   title: string;
// }

export interface SharesOwnedProps {}

export default SharesOwned;
