import styles from "./styles.module.scss";

const ProgressBar = (props: ProgressBarSteps) => {
  const { currentStep, totalSteps } = props;

  const Dots = (props: any): any => {
    const dots = [];
    for (var i = 0; i < totalSteps; i++) {
      dots.push(<div key={i} className={`${styles.step} ${i <= currentStep ? styles.activeStep : ""} `} />);
    }
    return dots;
  }

  return (
    <div className={styles.stepContainer}>
      <Dots />
    </div>
  )
}

interface ProgressBarSteps {
  currentStep: number;
  totalSteps: number;
}

export default ProgressBar;