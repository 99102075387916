import { useContext, useState } from "react";
import Layout from "../../Layouts/Layout/index";
import Checkbox from "../../Checkbox/index";
import Button from "../../Button/index";
import ProgressBar from "../../ProgressBar/index";
import { RouterContext } from "../../../contexts/routerContext";
import { SubmissionContext } from "../../../contexts/submissionContext";
import { ConnectorContext} from "../../../contexts/connectorContext";
import styles from "./styles.module.scss";

const InterestFr: React.FC<InterestProps> = (props) => {
  const routerContext = useContext(RouterContext);
  const submissionContext = useContext(SubmissionContext);
  const connectorContext = useContext(ConnectorContext) as any;
  const [selectedSecTypes, setSelectedSecTypes] = useState<string[]>([]);
  const { content } = props;
  const securities: [] = content.securities;

  const contentVal = securities.map((obj: { [key: string]: any }) => {
      for (let key in obj) {
          if (key !== "next") {
              return obj[key];
          }
      }
  });

  // const toggleSecurityType = (security: any) => {
  //   let tmpSelectedTypes = [...selectedSecTypes];

  //   if (selectedSecTypes.includes(security)) {
  //     tmpSelectedTypes.splice(selectedSecTypes.indexOf(security), 1);
  //     setSelectedSecTypes(tmpSelectedTypes);
  //     return;
  //   }

  //   setSelectedSecTypes([...tmpSelectedTypes, security]);
  // };

  const toggleSecurityType = (security: any) => {
    // Check if the security type is already selected
    const index = selectedSecTypes.indexOf(security);
    let newSelectedSecTypes;

    if (index > -1) {
        // If it's already selected, remove it from the array
        newSelectedSecTypes = [...selectedSecTypes];
        newSelectedSecTypes.splice(index, 1);
    } else {
        // If it's not selected, add it to the array
        newSelectedSecTypes = [...selectedSecTypes, security];
    }

    setSelectedSecTypes(newSelectedSecTypes);
  };


  return (
    <Layout>
      <Layout.Top>
        <section className={styles.top}>
          <h1 className={styles.title}>
            {content.title || "Which types of securities are you interested in?"}
          </h1>
          <p className="globalSubText">
            { content.body || "If you’re not sure, click continue to jump to the next step."}
          </p>
        </section>
      </Layout.Top>

      <Layout.Middle>
        <section slot="middle" className={styles.middle}>
          <br />
          <div className={styles.container}>
            {securities?.map((c, index) => {
              const item = contentVal[index];
              return (
                <div
                  className={styles.checkboxSecurityTypeContainer}
                  style={{
                    borderBottom: `${
                      index + 1 === content.length
                        ? "0px"
                        : "1px solid #EDEDED"
                    }`,
                  }}
                  onClick={() => toggleSecurityType(item)}
                  key={c}
                >
                  <Checkbox
                    checked={selectedSecTypes.indexOf(item) > -1}
                    style={{ paddingBottom: "12px", marginRight: "12px" }}
                  />
                  <div className={styles.sec}>{item}</div>
                </div>
              );
            })}
          </div>
        </section>
      </Layout.Middle>

      <Layout.Bottom>
        <Button
          onClick={() => {
            submissionContext?.updateData({
                submission_name: routerContext.state.screenName,
                submission_data: selectedSecTypes,
                submission_type: routerContext.state.screenType,
                submission_screen_id: routerContext.state.screenId,
                submission_company_id: connectorContext?.connector?.company_id,
                submission_connector_id: connectorContext?.connector?.connector_id
            });
            routerContext.goNext();
          }}
        >
            Continuer
        </Button>
        <br />
        <br />
        <ProgressBar
          currentStep={routerContext.state.screenIndex}
          totalSteps={routerContext.state.totalScreenCount}
        />
      </Layout.Bottom>
    </Layout>
  );
};



export interface InterestProps {
  id: string;
  name: string;
  content: any;
}

export default InterestFr;
