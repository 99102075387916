import { useState, useContext } from "react";
import Layout from "../../Layouts/Layout";
import Button from "../../Button/index";
import ProgressBar from "../../ProgressBar";
import Checkbox from "../../Checkbox";
import styles from "./styles.module.scss";
import { RouterContext } from "../../../contexts/routerContext";
import { SubmissionContext } from "../../../contexts/submissionContext";

const ContactInformation: React.FC<ContactInformationProps> = (props) => {
  const routerContext = useContext(RouterContext);
  const submissionContext = useContext(SubmissionContext);
  const [policyAccepted, setPolicyAccepted] = useState(false);
  const { content } = props;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [preferredName, setPreferredName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState<any>({});

  const validateForm = (): boolean => {
    let errs: any = {};

    if (!firstName) errs.first_name = ["required"];
    if (!lastName) errs.last_name = ["required"];
    if (!email) errs.email = ["required"];
    if (!policyAccepted) errs.policyAccepted = ["You must accept the terms and privacy policy"];

    setErrors(errs);

    return !Object.keys(errs).length; // If no errors, return true
  };

  return (
    <Layout>
      <Layout.Top>
        <section>
          <h1 className={styles.title}>
            {content.title ?? "Your Contact Information"}
          </h1>
        </section>
      </Layout.Top>

      <Layout.Middle>
        <section className={styles.contactInfo}>
          <div className={styles.row}>
            <label className={styles.label} style={{ marginRight: "10px" }}>
              First Name / Given Name

              {/* {#each Errors.traverseErrors(errors, 'first_name') as err}
                <span className={styles.err}>Error: {err}</span>
              {/each} */}
              <input
                className={styles.input}
                placeholder="John"
                type="text" /*bind:value={firstName}*/
                onChange={(event) => setFirstName(event.target.value)}
              />
              {errors.first_name && errors.first_name.map((err: string) => <span className={`${styles.err} ${styles.smallText}`}>Error: {err}</span>)}
            </label>

            <label className={styles.label}>
              Last Name / Surname
              {/* {#each Errors.traverseErrors(errors, 'last_name') as err}
                <span class="err">Error: {err}</span>
              {/each} */}
              <input
                className={styles.input}
                placeholder="Doe"
                type="text" /*bind:value={lastName}*/
                onChange={(event) => setLastName(event.target.value)}
              />
              {errors.last_name && errors.last_name.map((err: string) => <span className={`${styles.err} ${styles.smallText}`}>Error: {err}</span>)}
            </label>
          </div>

          <label className={styles.label} style={{ marginRight: "10px" }}>
            Preferred Name <span className={styles.optional}>(optional)</span>
            {/* {#each Errors.traverseErrors(errors, 'preferred_name') as err}
              <span class="err">Error: {err}</span>
            {/each} */}
            <input
              className={styles.input}
              placeholder="John Doe"
              type="text" /*bind:value={preferredName}*/
              onChange={(event) => setPreferredName(event.target.value)}
            />
          </label>
          <br />
          <div className={styles.row} style={{ marginTop: "1rem" }}>
            <label className={styles.label} style={{ marginRight: "10px" }}>
              Email Address<span className={styles.err}>*</span>
              {/* {#each Errors.traverseErrors(errors, 'email') as err}<span
                  class="err">Error: {err}</span>
              {/each} */}
              <input
                className={styles.input}
                placeholder="e.g john.doe@gmail.com"
                type="email" /*bind:value={email}*/
                onChange={(event) => setEmail(event.target.value)}
              />
              {errors.email && errors.email.map((err: string) => <span className={`${styles.err} ${styles.smallText}`}>Error: {err}</span>)}
            </label>

            <label className={styles.label}>
              Cell Phone <span className={styles.optional}>(optional)</span>
              {/* {#each Errors.traverseErrors(errors, 'phone') as err}
                <span class="err">Error: {err}</span>
              {/each} */}
              <input
                className={styles.input}
                placeholder="123-456-789"
                type="text"
                /*bind:value={phone}*/
                onChange={(event) => setPhone(event.target.value)}
              />
            </label>
          </div>

          <div className={styles.privacyPolicy}>
            <Checkbox
              checked={policyAccepted}
              onClick={() => setPolicyAccepted(!policyAccepted)}
            />
            <div className={styles.checkboxAgreement}>
              I agree to the{" "}
              <a
                href={content.privacyPolicyLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span
                  style={{
                    color: "var(--sharechest-launcher-clr-primary) !important",
                  }}
                >
                  Privacy Policy
                </span>
              </a>{" "}
              and{" "}
              <a
                href={content.termsOfServiceLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <span
                  style={{
                    color: "var(--sharechest-launcher-clr-primary) !important",
                  }}
                >
                  Terms of Service
                </span>
              </a>
            </div>
          </div>
        </section>
      </Layout.Middle>

      <Layout.Bottom>
        <Button disabled={!policyAccepted} onClick={() => {
          if (!validateForm()) return;

          const investorData = {
            user_first_name: firstName,
            user_last_name: lastName,
            user_preferred_name: preferredName,
            user_email: email,
            user_phone: phone,
          };
          submissionContext?.updateInvestor(investorData);
          routerContext.goNext();
        }}>Send Information</Button>
        <br />
        <br />
        <ProgressBar
          currentStep={routerContext.state.screenIndex}
          totalSteps={routerContext.state.totalScreenCount}
        />
      </Layout.Bottom>
    </Layout>
  );
};

interface ContactInformationContent {
  title: string;
  privacyPolicyLink: string;
  termsOfServiceLink: string;
}

export interface ContactInformationProps {
  content: ContactInformationContent;
}

export default ContactInformation;
