import * as React from "react";
import { submit } from "../api/submissions";
import { UserContext} from "./userContext";

// export interface SubmissionContextInterface {
//   submission: Submission;
//   setSubmission: (user: Submission) => {};
//   updae
// }

declare interface SubmissionContextInterface {
  submission: Submission;
  reset: () => void;
  replaceData: (data: any) => void;
  updateData: (data: any) => void;
  // replaceInvestor: (investor: Omit<Investor, "id">) => void;
  updateInvestor: (investor: Partial<Omit<Investor, "id">>) => void;
  getFullSubmission: () => Submission;
  submitData: () => Promise<any>;
}

export const SubmissionContext = React.createContext<SubmissionContextInterface | null>(
  null
);

interface SubmissionProviderProps {
  children: any;
  initialSubmission: Submission;
}

export const SubmissionProvider: React.FC<SubmissionProviderProps> = (
  props
) => {
  const { children, initialSubmission } = props;
  const [submission, setSubmission] = React.useState<Submission>({investor: {}, data: []});
  const [investor, setInvestor] = React.useState<Investor>();
  const userContact = React.useContext(UserContext);
  const token = userContact?.token as string;

  const updateData = (data: any) => {
    submission.data.push(data);
    //setSubmission({...submission, data: {...submission?.data, ...data}});
  }

  /*const replaceInvestor = (investor: Omit<Investor, "id">) =>
      setInvestor(investor);*/

  const updateInvestor = (investor: Partial<Omit<Investor, "id">>) =>
      setSubmission({...submission, investor});

  const submitData = async () => {
    try {
      const response = await submit(submission, token);
      /*console.log('Submission response:', response);*/
    } catch (error) {
      console.error('Error submitting data:', error);
    }
  };

  const getFullSubmission = () => {
    return submission;
  }

  const value: any = {
    submission,
    updateData,
    updateInvestor,
    submitData,
    getFullSubmission,
    // setSubmission,
  };

  return (
    <SubmissionContext.Provider value={value}>
      {children}
    </SubmissionContext.Provider>
  );
};

export default {
  SubmissionProvider,
};
