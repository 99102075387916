import LogoAndName from "../LogoAndName";
import styles from "./styles.module.scss";

const Footer = () => {
  return (
    <div className={styles.footer}>
      {/* <div className={styles.text}>Proudly powered by </div>
      <a href="http://sharechest.io" target="_blank" rel="noopener noreferrer">
        <LogoAndName height={16} width={86} />
      </a> */}
    </div>
  );
};

export default Footer;
