import React from "react";
import Layout from "../Layouts/Layout"
import styles from "./styles.module.scss";

interface ErrorViewProps {
    title: string,
    errorMessage: string,
    resolutionMessage: string,
    buttons?: React.ReactNode,
}

const ErrorView = ({
    title,
    errorMessage,
    resolutionMessage,
    buttons,
}: ErrorViewProps) => {
    return (
      <Layout>
          <Layout.Top>
              <h1>{title}</h1>
              <br/><br/><br/>
              <h3>{errorMessage}</h3>
          </Layout.Top>
          <Layout.Middle className={styles.middle}>
              <div>{resolutionMessage}</div>
          </Layout.Middle>
          <Layout.Bottom>
              {buttons}
          </Layout.Bottom>
      </Layout>
    );
};


export default ErrorView;
