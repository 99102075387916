import styles from "./styles.module.scss";

const ImageBanner = ({
  welcomeMessage,
  bgImageUrl = "https://sharechest.s3.us-east-2.amazonaws.com/default_banner_image.png",
}: ImageBannerProps) => {
  return (
    <div className={styles.container} style={{ backgroundImage: `url(${bgImageUrl})` }}>
      <div className={styles.title}>
        <h1 style={{ color: "white" }}>{welcomeMessage}</h1>
      </div>
    </div>
  )
}

export interface ImageBannerProps {
  welcomeMessage: string;
  bgImageUrl: string;
}

export default ImageBanner;