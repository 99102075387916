// export interface ScreenRouterStore
//   extends ScreenRouterActions,
//     Readable<ScreenRouterState> {}

import * as React from "react";
import { ScreenComponentResolver, ScreenRouterState } from "../types/screenRouter";
import { ScreenTypes } from "../components/screens/resolver";
import { SubmissionContext } from "./submissionContext";
import { ConnectorContext } from "./connectorContext";
import { useContext } from "react";


export interface RouterContextInterface {
  screenComponent: React.FC | null;
}

export const RouterContext = React.createContext<{
  state: ScreenRouterState;
  goNext: any;
  goBack: any;
  goSkip: any;
  setScreens: (screens: IScreen[]) => void;
  getScreens: () => any[];
  setDefaultScreens: (screens: IScreen[]) => void;
  goToIndex:(state: ScreenRouterState, newIndex: number) =>void;
}>({
  state: {
    totalScreenCount: 0,
    screenId: "",
    screenIndex: 0,
    screenName: "",
    screenType: "",
    screenContent: {},
    screenComponent: null,
  },
  goNext: () => {},
  goBack: () => {},
  goSkip: () => {},
  setDefaultScreens: (screens: IScreen[]) => {},
  setScreens: (screens: IScreen[]) => {},
  getScreens: () => [],
  goToIndex:(state: ScreenRouterState, newIndex: number) =>{},
});

interface RouterProviderProps {
  children: any;
  screens: IScreen[];
  componentResolver: ScreenComponentResolver;
}

export const RouterProvider: React.FC<RouterProviderProps> = ({
  children,
  screens: defaultScreens,
  componentResolver,
}) => {
  const [screens, setScreens] = React.useState<IScreen[]>(defaultScreens);
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [shouldSubmit, setShouldSubmit] = React.useState(false);
  const submissionContext = useContext(SubmissionContext);
  const connectorContext = useContext(ConnectorContext);
  const [currentState, setCurrentState] = React.useState<ScreenRouterState>({
    totalScreenCount: 0,
    screenId: "",
    screenIndex: 0,
    screenName: "",
    screenType: "",
    screenContent: {},
    screenComponent: null,
  });

  React.useEffect(() => {
    setDefaultScreens(defaultScreens);
  }, []);

  React.useEffect(() => {
    if (shouldSubmit && !hasSubmitted) {
      // If shouldSubmit is true and the form hasn't been submitted, submit the form.
      submissionContext?.submitData();
      // Set hasSubmitted to true to prevent duplicate submissions.
      setHasSubmitted(true);
      // Reset shouldSubmit to false
      setShouldSubmit(false);
    }
  }, [submissionContext?.submission, shouldSubmit]);


  const setDefaultScreens = (screens: IScreen[]) => {
    if (screens.length == 0) {
      return;
    }
    //   //|| componentResolver == null) {
    //   throw new Error("Missing screens"); // TODO: necessary? // TODO: proper error handling
    // } else {

    // Sort the screens based on screen_sequence_number
    const sortedScreens = [...screens].sort((a, b) => a.screen_sequence_number - b.screen_sequence_number);

    // Reassign screen_sequence_number values starting from 0
    sortedScreens.forEach((screen, index) => {
      screen.screen_sequence_number = index;
    });

    setScreens(sortedScreens); // You might want to store the newly assigned screens

    const firstScreen = sortedScreens[0];

    setCurrentState({
      totalScreenCount: screens.length,
      screenId: firstScreen.screen_id,
      screenIndex: firstScreen.screen_sequence_number,
      screenName: firstScreen.screen_name,
      screenType: firstScreen.screen_type,
      screenContent: firstScreen.screen_data,
      screenComponent: componentResolver(firstScreen.screen_type as ScreenTypes),
    });
  };

  const getScreens = () => {
    return screens;
  }

  const goToIndex = (state: ScreenRouterState, newIndex: number) => {
    if (newIndex > state.totalScreenCount - 1) return state;
    if (newIndex < 0) return state;

    const newScreen = screens[newIndex];
    if (newScreen == null) return state;

    const screenComponent = componentResolver(newScreen.screen_type as ScreenTypes);
    if (screenComponent == null) return state;

    setCurrentState({
      ...state,
      screenId: newScreen.screen_id,
      screenIndex: newScreen.screen_sequence_number,
      screenName: newScreen.screen_name,
      screenType: newScreen.screen_type,
      screenContent: newScreen.screen_data ?? {},
      screenComponent,
    });
  };

  const goNext = () => {
    let newIndex = screens.findIndex(
        (sc) => sc.screen_sequence_number === currentState.screenIndex + 1
    );

    // Customize code for connector "aeb8b379-dae8-4288-80f4-5ce7e9db4fe2"
    // Check if current screen type is "Securities" and the submission data includes "I'm interested in investing"
    if (connectorContext?.connector?.connector_id === "00fede6b-4a5a-4644-9f2f-c65ccb70d2f9" && currentState.screenType === "Securities") {
      if (!submissionContext?.submission?.data[submissionContext?.submission?.data.length-1]?.submission_data.includes("I'm interested in investing")) {
        // Skip the next screen by incrementing newIndex
        newIndex = screens.findIndex(
            (sc) => sc.screen_sequence_number === currentState.screenIndex + 2
        );
      }
    }

    // Customize code for connector "afc7f0b4-a4e2-4d49-9395-cd08377cfaa0"
    if (connectorContext?.connector?.connector_id === "afc7f0b4-a4e2-4d49-9395-cd08377cfaa0" && currentState.screenType === "Securities") {
      const submissionData = submissionContext?.submission?.data[submissionContext?.submission?.data.length-1]?.submission_data;

      if (currentState.screenName === "What are you interested in?" &&
          !submissionData.includes("Equity Stake in UFFS") &&
          !submissionData.includes("Purchasing UFFS Score Token")) {
        // Skip the next screen by incrementing newIndex
        newIndex = screens.findIndex(
            (sc) => sc.screen_sequence_number === currentState.screenIndex + 2
        );
      }

      if (currentState.screenName === "How would you like to invest?" &&
          !submissionData.includes("Crypto")){
        newIndex = screens.findIndex((sc) => sc.screen_sequence_number === currentState.screenIndex + 2);
      }
    }

    const remainingScreens = screens.filter(
        (sc) => sc.screen_sequence_number > currentState.screenIndex
    );

    const requiredScreens = ['Securities', 'Amount', 'Contact'];
    const hasRequiredScreens = requiredScreens.some((type) =>
        remainingScreens.some((sc) => sc.screen_type === type)
    );

    if (!hasRequiredScreens && !hasSubmitted) {
      // If there are no remaining required screens and the form hasn't been submitted, set shouldSubmit to true.
      setShouldSubmit(true);
    }

    // Go to the next screen.
    goToIndex(currentState, newIndex);
  };



  const goBack = () => {
    const newIndex = screens.findIndex(
      (sc) => sc.screen_sequence_number === currentState.screenIndex - 1
    );
    goToIndex(currentState, newIndex);
  };

  const goSkip = () => {
    // find the index of the login screen
    const newIndex = screens.findIndex(
        (sc) => sc.screen_type === "Login"
    );
    goToIndex(currentState, newIndex);
  }

  const value: any = {
    state: currentState,
    setScreens,
    getScreens,
    setDefaultScreens,
    goNext,
    goBack,
    goSkip,
    goToIndex,
  };

  return (
    <RouterContext.Provider value={value}>{children}</RouterContext.Provider>
  );
};

export default {
  RouterProvider,
};
