import styles from "./styles.module.scss";

const Newspaper = ({ width = 20, height = 18}: NewspaperProps) => {
  return (
    <svg className={styles.svg} width={width} height={height} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 17H3C2.46957 17 1.96086 16.7893 1.58579 16.4142C1.21071 16.0391 1 15.5304 1 15V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H13C13.5304 1 14.0391 1.21071 14.4142 1.58579C14.7893 1.96086 15 2.46957 15 3V4L17 17ZM17 17C16.4696 17 15.9609 16.7893 15.5858 16.4142C15.2107 16.0391 15 15.5304 15 15V4L17 17ZM17 17C17.5304 17 18.0391 16.7893 18.4142 16.4142C18.7893 16.0391 19 15.5304 19 15V6C19 5.46957 18.7893 4.96086 18.4142 4.58579C18.0391 4.21071 17.5304 4 17 4H15L17 17ZM11 1H7H11ZM5 13H11H5ZM5 5H11V9H5V5Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
}

interface NewspaperProps {
  width?: number;
  height?: number;
}

export default Newspaper;