const LogoAndName = (props: LogoAndNameProps) => {
	const { width, height } = props

	return (
		<svg width={width} height={height} viewBox="0 0 920 181" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M269.013 80.8692C250.562 70.9958 243.219 63.3438 243.219 53.7789C243.219 44.214 250.562 37.7963 261.485 37.7963C269.384 37.7963 276.727 41.0668 282.898 47.3612C288.266 52.7916 290.673 57.2346 293.45 67.1081C293.573 67.4166 293.82 67.6635 294.191 67.6635H297.153C297.585 67.6635 297.955 67.2932 297.955 66.7995L294.252 31.3785C294.191 31.0083 293.882 30.6997 293.45 30.6997H291.105C290.735 30.6997 290.426 30.9465 290.365 31.3168C289.562 34.7108 287.711 36.6238 284.872 36.6238C283.145 36.6238 280.306 35.945 277.159 34.5257C270.679 32.119 264.323 30.6997 258.214 30.6997C251.364 30.6997 244.021 33.4766 238.406 37.9814C231.556 43.5352 228.224 51.002 228.224 60.5669C228.224 74.2663 235.937 84.0163 255.622 94.26C268.273 101.048 277.406 107.959 281.787 114.562C283.391 116.969 284.255 120.486 284.255 124.806C284.255 136.284 275.493 144.244 262.657 144.244C246.86 144.244 235.999 134.679 227.175 113.513H223.04L228.285 150.354H232.173C232.358 147.083 234.456 144.615 237.11 144.615C239.023 144.615 242.046 145.293 245.379 146.528C252.043 149.119 259.078 150.539 266.113 150.539C286.477 150.539 301.904 136.839 301.904 118.944C301.842 104.504 292.216 93.3962 269.013 80.8692Z" fill="#1B1735"/>
			<path d="M376.811 130.36V95.8029C376.811 77.5987 369.468 68.2189 355.213 68.2189C344.846 68.2189 337.502 72.4151 329.418 82.7823V30.021L328.555 29.5273C322.569 31.6255 318.187 32.983 308.376 35.7599L303.871 37.0559C303.748 37.1176 303.624 37.241 303.624 37.3644V39.4625C303.624 39.7093 303.809 39.8328 304.056 39.8328C304.55 39.7711 305.043 39.771 305.722 39.771C313.251 39.771 314.67 41.1904 314.67 48.6572V130.483C314.67 142.64 313.621 144.183 303.439 145.602V148.194H341.328V145.602C331.146 144.553 329.418 142.455 329.418 130.483V88.6446C336.762 80.6842 342.069 77.7221 348.919 77.7221C357.681 77.7221 362.063 83.9547 362.063 96.1114V130.483C362.063 142.27 360.335 144.553 350.153 145.602V148.194H387.363V145.602C377.86 143.751 376.811 142.393 376.811 130.36Z" fill="#1B1735"/>
			<path d="M451.801 139.924C447.79 139.924 446.555 137.517 446.555 129.865V95.9868C446.555 87.1007 445.691 82.2874 443.223 78.2763C439.521 71.6735 431.992 68.2178 421.255 68.2178C412.307 68.2178 403.853 70.6244 398.978 74.6355C394.596 78.2763 391.758 83.3365 391.758 87.6561C391.758 91.6672 395.09 95.1229 399.286 95.1229C403.482 95.1229 407.185 91.6672 407.185 87.8412C407.185 87.1624 407 86.2985 406.815 85.0643C406.445 83.5216 406.259 82.1023 406.259 80.8681C406.259 76.1782 411.875 72.3522 418.91 72.3522C427.487 72.3522 432.239 77.4124 432.239 86.7922V97.4061C405.21 108.144 402.248 109.563 394.658 116.166C390.77 119.621 388.364 125.545 388.364 131.284C388.364 142.207 395.892 149.859 406.815 149.859C414.528 149.859 421.748 146.218 432.424 137.208C433.288 146.403 436.435 149.859 443.655 149.859C449.641 149.859 453.282 147.761 459.453 141.158V136.653C456.552 139.06 454.454 139.924 451.801 139.924ZM432.301 126.718C432.301 132.087 431.437 133.691 427.734 135.913C423.538 138.319 418.601 139.739 414.899 139.739C408.728 139.739 403.853 133.814 403.853 126.348V125.669C403.853 115.425 411.073 109.193 432.301 101.541V126.718Z" fill="#1B1735"/>
			<path d="M517.95 77.4124C517.95 71.6735 514.247 68.2178 508.323 68.2178C500.98 68.2178 495.858 72.2289 487.28 84.5707V68.588L486.416 68.2178C477.098 72.0437 470.804 74.2652 460.437 77.5975V80.3744C462.905 79.8808 464.448 79.6956 466.608 79.6956C470.989 79.6956 472.594 82.4726 472.594 90.1245V133.568C472.594 142.269 471.359 143.441 460.128 145.539V148.131H502.276V145.539C490.366 145.046 487.342 142.392 487.342 132.519V93.4568C487.342 87.9029 494.871 79.202 499.622 79.202C500.671 79.202 502.276 80.0659 504.189 81.7938C507.027 84.2004 508.94 85.2495 511.223 85.2495C515.358 85.2495 517.95 82.2874 517.95 77.4124Z" fill="#1B1735"/>
			<path d="M657.841 120.979C649.387 133.136 643.092 137.332 633.095 137.332C616.928 137.332 605.882 123.448 605.882 103.454C605.882 85.3731 615.508 73.2164 629.763 73.2164C636.057 73.2164 638.341 75.1294 640.13 81.5471L641.179 85.3731C642.599 90.4332 645.746 93.3335 649.633 93.3335C654.2 93.3335 657.903 90.063 657.903 86.0519C657.903 76.3018 645.622 68.1562 630.874 68.1562C622.605 68.1562 614.213 71.3034 607.178 77.0424C597.674 84.8794 592.429 96.851 592.429 111.229C592.429 133.63 606.314 149.797 625.752 149.797C633.281 149.797 639.945 147.391 645.931 142.516C651.176 138.319 654.694 133.321 660.309 122.522L657.841 120.979Z" fill="#1B1735"/>
			<path d="M732.077 130.359V95.8021C732.077 77.5979 724.733 68.2182 710.478 68.2182C700.111 68.2182 692.768 72.4144 684.684 82.7815V29.9585L683.82 29.4648C677.834 31.563 673.453 32.9206 663.641 35.6975L658.89 37.1168V39.8937C659.569 39.7086 660.124 39.7086 660.988 39.7086C668.516 39.7086 669.936 41.1279 669.936 48.5947V130.421C669.936 142.578 668.887 144.12 658.705 145.54V148.131H696.594V145.54C686.412 144.491 684.684 142.392 684.684 130.421V88.5821C692.027 80.6217 697.334 77.6596 704.184 77.6596C712.947 77.6596 717.328 83.8922 717.328 96.0489V130.421C717.328 142.207 715.6 144.491 705.418 145.54V148.131H742.629V145.54C733.126 143.75 732.077 142.392 732.077 130.359Z" fill="#1B1735"/>
			<path d="M855.314 106.908L836.369 95.8007C831.432 93.0237 828.841 88.7041 828.841 84.0142C828.841 76.9177 834.271 72.2278 842.355 72.2278C852.352 72.2278 857.597 77.9668 861.67 93.5791H864.323L863.644 69.9446H861.732L861.361 70.3148C859.757 71.549 859.633 71.7341 858.893 71.7341C857.844 71.7341 856.054 71.3639 854.141 70.4999C850.439 69.0806 846.428 68.4019 842.231 68.4019C827.853 68.4019 818.042 77.1028 818.042 89.7532C818.042 99.5032 823.657 106.229 838.591 114.745L848.773 120.484C854.944 123.94 857.906 128.136 857.906 133.505C857.906 141.157 852.29 146.032 843.342 146.032C831.247 146.032 825.076 139.429 821.065 121.718H818.227V148.809H820.51C821.744 147.081 822.423 146.711 824.521 146.711C826.434 146.711 828.409 147.081 832.605 148.13C837.542 149.179 841.923 149.858 845.564 149.858C858.893 149.858 870.124 139.799 870.124 127.643C870.062 118.942 865.989 113.203 855.314 106.908Z" fill="#1B1735"/>
			<path d="M916.899 134.742C913.011 139.247 910.234 140.79 906.347 140.79C899.867 140.79 897.214 136.285 897.214 125.177V75.5016H914.924V69.9478H897.214V49.8306C897.214 48.1027 896.843 47.5474 895.979 47.5474C894.93 49.2752 893.696 50.8179 892.462 52.4224C885.983 61.9873 879.997 68.405 875.43 70.9968C873.517 72.231 872.468 73.2801 872.468 74.2674C872.468 74.7611 872.653 75.1313 873.147 75.5016H882.465V127.769C882.465 142.333 887.71 149.799 897.892 149.799C906.655 149.799 913.32 145.603 919.12 136.594L916.899 134.742Z" fill="#1B1735"/>
			<path d="M583.361 119.622C574.906 132.827 567.378 137.826 556.147 137.826C546.52 137.826 539.115 133.321 534.055 123.941C531.525 119.066 530.106 114.562 529.365 108.329L581.818 93.3336C581.694 92.6548 581.571 91.976 581.386 91.3589C580.09 85.62 578.3 81.9791 575.215 78.0297C569.97 71.7971 561.886 68.1562 552.753 68.1562C530.97 68.1562 516.098 85.4965 516.098 110.859C516.098 134.802 528.748 149.736 548.927 149.736C565.773 149.736 578.794 139.492 586.137 120.732L583.361 119.622ZM529.18 94.4444C531.093 80.7449 537.079 74.4506 547.754 74.4506C558.245 74.4506 562.503 79.1405 564.848 93.6421L528.995 103.762C528.563 101.911 528.81 96.9127 529.18 94.4444Z" fill="#1B1735"/>
			<path d="M809.652 119.622C801.197 132.827 793.669 137.826 782.438 137.826C772.811 137.826 765.406 133.321 760.346 123.941C757.816 119.066 756.397 114.562 755.656 108.329L808.109 93.3336C807.985 92.6548 807.862 91.976 807.677 91.3589C806.381 85.62 804.591 81.9791 801.506 78.0297C796.261 71.7971 788.177 68.1562 779.044 68.1562C757.261 68.1562 742.389 85.4965 742.389 110.859C742.389 134.802 755.039 149.736 775.218 149.736C792.064 149.736 805.085 139.492 812.428 120.732L809.652 119.622ZM755.409 94.4444C757.322 80.7449 763.308 74.4506 773.984 74.4506C784.474 74.4506 788.732 79.1405 791.077 93.6421L755.224 103.762C754.854 101.911 755.039 96.9127 755.409 94.4444Z" fill="#1B1735"/>
			<path d="M98.7784 113.822L96.5053 179.746C96.4988 179.94 96.7131 180.07 96.882 179.973L110.716 171.972C110.774 171.94 110.82 171.881 110.832 171.817L129.927 94.7737C129.979 94.5598 129.751 94.3847 129.557 94.4949L100.233 111.397C99.3629 111.896 98.8109 112.811 98.7784 113.822Z" fill="#1B1735"/>
			<path d="M121.023 166.022L132.213 159.538C132.252 159.512 132.285 159.48 132.31 159.441L182.638 64.4258C182.755 64.2054 182.508 63.9655 182.293 64.0952L149.417 83.0528C149.365 83.0852 149.326 83.1306 149.307 83.189L120.666 165.73C120.581 165.944 120.821 166.138 121.023 166.022Z" fill="#1B1735"/>
			<path d="M134.915 19.2622L101.532 0.032334C101.37 -0.0584343 101.169 0.0517843 101.156 0.239804L97.5772 77.7689C97.5707 77.905 97.6746 78.0152 97.811 78.0282C107.339 78.7544 116.035 81.0819 123.127 84.6737C123.277 84.7515 123.458 84.6608 123.484 84.4987L135.032 19.5281C135.051 19.4243 135.006 19.3206 134.915 19.2622Z" fill="#1B1735"/>
			<path d="M149.248 27.8996L135.174 79.6635C135.116 79.8774 135.35 80.059 135.538 79.9423L182.917 52.6275C182.995 52.5821 183.04 52.4978 183.04 52.4135V48.3808C183.04 47.5315 182.585 46.747 181.852 46.3256L149.612 27.7505C149.476 27.6662 149.294 27.7375 149.248 27.8996Z" fill="#1B1735"/>
			<path d="M84.3149 113.822L86.588 179.746C86.5945 179.94 86.3802 180.07 86.2113 179.973L72.3777 171.972C72.3192 171.94 72.2738 171.881 72.2608 171.817L53.1665 94.7737C53.1145 94.5598 53.3418 94.3847 53.5367 94.4949L82.8601 111.397C83.7303 111.896 84.2824 112.811 84.3149 113.822Z" fill="#1B1735"/>
			<path d="M62.0705 166.022L50.8802 159.538C50.8412 159.512 50.8088 159.48 50.7828 159.441L0.455647 64.4258C0.338741 64.2054 0.585526 63.9655 0.79985 64.0952L33.6758 83.0528C33.7278 83.0852 33.7668 83.1306 33.7862 83.189L62.4277 165.73C62.5121 165.944 62.2718 166.138 62.0705 166.022Z" fill="#1B1735"/>
			<path d="M48.1783 19.2622L81.5608 0.032334C81.7232 -0.0584343 81.9245 0.0517843 81.9375 0.239804L85.5161 77.7689C85.5226 77.905 85.4186 78.0152 85.2823 78.0282C75.7546 78.7544 67.0583 81.0819 59.9661 84.6737C59.8167 84.7515 59.6349 84.6608 59.6089 84.4987L48.0614 19.5281C48.0419 19.4243 48.0874 19.3206 48.1783 19.2622Z" fill="#1B1735"/>
			<path d="M33.8449 27.8996L47.9188 79.6635C47.9773 79.8774 47.7435 80.059 47.5551 79.9423L0.176567 52.6275C0.0986328 52.5821 0.0531578 52.4978 0.0531578 52.4135V48.3808C0.0531578 47.5315 0.50779 46.747 1.24169 46.3256L33.4812 27.7505C33.6176 27.6662 33.7994 27.7375 33.8449 27.8996Z" fill="#1B1735"/>
		</svg>
	);
}

interface LogoAndNameProps {
	width: number;
	height: number;
}

export default LogoAndName;