import Layout from "../../Layouts/Layout";
import ProgressBar from "../../ProgressBar";
import Button from "../../Button";
import {RouterContext} from "../../../contexts/routerContext";
import {useState, useContext} from "react";
import styles from "./styles.module.scss";
import SharechestLogo from "../../../assets/sharechestLogo";
import {UserContext} from "../../../contexts/userContext";
import {CompanyContext} from "../../../contexts/companyContext";
import axios from "axios";
import {createHmac} from 'crypto';
import {CognitoIdentityServiceProvider} from 'aws-sdk';
import Modal from 'react-modal';
import {ConnectorContext} from "../../../contexts/connectorContext";

const Login: React.FC<LoginProps> = ({}) => {
    const routerContext = useContext(RouterContext);
    const userContext = useContext(UserContext);
    const companyContext = useContext(CompanyContext);
    const [data, setData] = useState({email: "", password: ""});
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const companyId = companyContext?.companyId;
    const signUpUrl = process.env.REACT_APP_DASHBOARD_URL + `/signup?id=${companyId}`;

    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID as string;
    const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET as string;
    const USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID as string;

    //get connector from context
    const connectorContext = useContext(ConnectorContext);
    const connector : any = connectorContext?.connector;

    function generateSecretHash(username: string, clientId: string, clientSecret: string) {
        return createHmac('SHA256', clientSecret)
            .update(username + clientId)
            .digest('base64');
    }

    //generateSecretHash(data.email, CLIENT_ID, CLIENT_SECRET);


    const login = async (data: any) => {
        //generateSecretHash(data.email, CLIENT_ID, CLIENT_SECRET);
        setIsLoading(true);

		
		
		const cognito = new CognitoIdentityServiceProvider({
				region: USER_POOL_ID.split('_')[0]
			});
			
		const auth_param: {[key:string]:string} ={
					'USERNAME': data.email,
					'PASSWORD': data.password
				}
				
		const params = {
				AuthFlow: 'USER_PASSWORD_AUTH',
				ClientId: CLIENT_ID,
				AuthParameters: auth_param
			};

		if(CLIENT_SECRET && CLIENT_SECRET!=''){
			const secretHash = generateSecretHash(data.email, CLIENT_ID, CLIENT_SECRET);
			params['AuthParameters']['SECRET_HASH']=secretHash
		}
			
        try {
            const response = await cognito.initiateAuth(params).promise();

            // @ts-ignore
            const accessToken = response.AuthenticationResult.AccessToken;
            // @ts-ignore
            const jwtObj = JSON.parse(atob(accessToken.split(".")[1]));
            const userId = jwtObj.username;

            userContext?.setToken(accessToken as string);
            await axios
                .get(`${process.env.REACT_APP_BACKEND_URL}/user/sub/${userId}/`, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                })
                .then((res) => {
                    setIsLoading(false);

                    // logout if user has no roles
                    if (res.data.user_role.length === 0 || !checkRoles(res.data)) {
                        //onLogout();
                        alert('No permission, please contact admin!');
                        setIsLoading(false);
                        return;
                    }

                    const name = res.data.identity
                        ? res.data.identity["given_name"]
                        : "User";
                    userContext?.setUser({
                        id: res.data.user_id,
                        name: res.data.user_first_name,
                        tag: res.data.user_tag,
                    });

                    resetScreens();
                    routerContext.goNext();
                })
                .catch((e) => {
                    console.log(e);
                    setIsError(true);
                    setIsLoading(false);
                });

            return response.AuthenticationResult;
        } catch (error) {
            console.error('Error during signIn:', error);
            setIsError(true);
            setIsLoading(false);
            throw error;
        }

    };

    const checkRoles = (user: any) => {
        let roles = user.user_role;
        let foundRoles = roles.filter((role: {
            role_name: string;
        }) => role.role_name === 'admin' || role.role_name === 'investor');

        if (foundRoles.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    const resetScreens = () => {
        const screens = [
            {
                screen_id: "id_welcome",
                screen_name: "Welcoming",
                screen_type: "Welcoming",
                screen_status: 1,
                screen_sequence_number: 0,
                screen_data: "{}",
            },
            {
                screen_id: "id_order_preview",
                screen_name: "OrderPreview",
                screen_type: "OrderPreview",
                screen_status: 1,
                screen_sequence_number: 1,
                screen_data: "{}",
            },
            {
                screen_id: "id_updated_thank_you",
                screen_name: "UpdatedThankYou",
                screen_type: "UpdatedThankYou",
                screen_status: 1,
                screen_sequence_number: 2,
                screen_data: {},
            }
        ];

        routerContext.setDefaultScreens(screens);
        routerContext.setScreens(screens);
    }

    const CustomFooter = () => (
        <div className={styles.footer}>
            <div style={{marginBottom: "2px"}} className={styles.text}>
                Powered by Alpaca
            </div>
            <div style={{height: "10px"}}></div>
            <div className={styles.text}>
                Brokerage services are provided by Alpaca Securities LLC (“Alpaca”),
                member FINRA/SIPC, a wholly-owned subsidiary of AlpacaDB, Inc.
                Technology is offered by Sharechest. This is not an offer, solicitation
                of an offer, or advice to buy or sell securities, or open a brokerage
                account in any jurisdiction where Alpaca is not registered (Alpaca is
                registered only in the United States). All accounts and trade executions
                are powered by Alpaca’s technology. View Alpaca’s disclosure at
                https://apaca.markets/disclosures.
            </div>
        </div>
    );

    return (
        <Layout customFooter={CustomFooter}>
            <Layout.Middle>
                <section
                    className={styles.loginContainer}
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        // marginTop: "5%",

                        // marginBottom:"5%",
                    }}
                >
                    <div
                        className={styles.banner}
                        style={{
                            backgroundImage:
                                `url("${connector?.connector_launcher?.launcher_image_url || "https://sharechest-core.s3-us-east-2.amazonaws.com/Sharechest/sharechest-widget-image.gif"}")`,
                        }}
                    />
                    <div
                        style={{
                            width: "100%",
                            maxWidth: "450px",
                        }}
                    >
                        {isError && (
                            <div
                                style={{
                                    backgroundColor: "#ecc8cb",
                                    padding: "12px",
                                    borderRadius: "8px",
                                    fontWeight: 500,
                                    maxWidth: "300px",
                                    margin: "12px auto",
                                    textAlign: "center",
                                }}
                            >
                                Unable to log in. Please try again.
                            </div>
                        )}

                        {userContext?.user.id === '' ? (
                            <div
                                style={{
                                    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.1)",
                                    display: "flex",
                                    flexDirection: "column",
                                    width: "100%",
                                    maxWidth: "450px",
                                    borderRadius: "10px",
                                }}
                            >
                                <input
                                    className={styles.input}
                                    placeholder="Email"
                                    value={data.email}
                                    onChange={(e) => setData({...data, email: e.target.value})}
                                />
                                <hr color="#EDEDED"/>
                                <input
                                    className={styles.input}
                                    placeholder="Password"
                                    type="password"
                                    value={data.password}
                                    onChange={(e) =>
                                        setData({...data, password: e.target.value})
                                    }
                                />
                            </div>
                        ) : (
                            <h1>You're already logged in!</h1>
                        )}
                    </div>
                    {/*<div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{ cursor: "pointer" }}
              onClick={() => console.log("clicked facebook sign in")}
            >
              <img
                src="/Facebook-Sign-In.png"
                style={{ width: "180px", height: "40px" }}
              />
            </div>
            <div style={{ width: "30px" }}></div>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => console.log("clicked google sign in")}
            >
              <img
                src="/Google-Sign-In.png"
                style={{ width: "180px", height: "40px" }}
              />
            </div>
          </div>*/}
                    <span style={{cursor: 'pointer', color: '#7871f4', textAlign: 'center'}}
                          onClick={() => setIsOpen(true)}>Disclaimer</span>
                </section>
            </Layout.Middle>

            <Layout.Bottom>
                {userContext?.user.id === '' ? (
                    <Button
                        disabled={isLoading}
                        onClick={() => {
                            setIsLoading(true);
                            login(data);
                        }}
                    >
                        Login
                    </Button>
                ) : (
                    <Button
                        onClick={() => {
                            //routerContext.goNext();
                            resetScreens();
                        }}
                    >
                        Continue
                    </Button>
                )}
                <br/>
                <br/>
                <ProgressBar
                    currentStep={routerContext.state.screenIndex}
                    totalSteps={routerContext.state.totalScreenCount}
                />
            </Layout.Bottom>

            {userContext?.user.id === '' && (
                <p className={styles.signUp}>
                    Don&apos;t have an account? &nbsp;
                    <a href={signUpUrl} target="_blank">
                        Sign up
                    </a>
                </p>
            )}

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setIsOpen(false)}
                contentLabel="My dialog"
                style={{
                    content: {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        background: '#f3f3f3', // Changed to a lighter color
                        overflow: 'auto',
                        WebkitOverflowScrolling: 'touch',
                        borderRadius: '25px',
                        outline: 'none',
                        padding: '20px',
                        width: '50%',  // Made the modal smaller
                        height: '40%',  // Made the modal smaller
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)', // Added a shadow for a more "elevated" look
                    },
                    overlay: {
                        zIndex: 150000,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)' // This will give a semi-transparent dark background
                    }
                }}
            >
                <h2 style={{color: '#333', fontFamily: 'Arial, sans-serif'}}>Notice</h2>
                <p style={{marginBottom: '20px', color: '#666', lineHeight: '1.6', fontFamily: 'Arial, sans-serif'}}>
                    Sharechest is a communication utility allowing communication from investors to a broker-dealer and
                    one-way communication from investors to issuers. Sharechest does not receive compensation based on
                    securities transactions, capital raises, or referrals. This service does not constitute an offer to
                    sell, a solicitation of an offer to buy, or a recommendation of any security or any other product or
                    service by Sharechest or any other third party regardless of whether such security, product or
                    service is referenced in this service. Furthermore, nothing in this service is intended to provide
                    tax, legal, or investment advice and nothing in this service should be construed as a recommendation
                    to buy, sell, or hold any investment or security or to engage in any investment strategy or
                    transaction. Sharechest does not represent that the securities, products, or services discussed in
                    this service are suitable for any particular investor. You are solely responsible for determining
                    whether any investment, investment strategy, security or related transaction is appropriate for you
                    based on your personal investment objectives, financial circumstances and risk tolerance. You should
                    consult your business advisor, attorney, or tax and accounting advisor regarding your specific
                    business, legal or tax situation.
                </p>
                <button style={{
                    cursor: 'pointer',
                    padding: '10px 20px',
                    background: '#7871f4',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '4px',
                    alignSelf: 'flex-end'
                }} onClick={() => setIsOpen(false)}>Close
                </button>
            </Modal>


        </Layout>
    );
};

export interface LoginProps {
}

export default Login;
