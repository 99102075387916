// import
import BannerLayout from "../../Layouts/BannerLayout";
import Button from "../../Button";
import ProgressBar from "../../ProgressBar";
import GenericBanner from "../../GenericBanner";
import Curve from "../../Curve";
import styles from "./styles.module.scss";
import { useContext } from "react";
import { RouterContext } from "../../../contexts/routerContext";

const ThankYouFr: React.FC<ThankYouProps> = (props) => {
  const routerContext = useContext(RouterContext);
  const { content } = props;

  const company = {
    logo:
      "https://sharechest-core.s3-us-east-2.amazonaws.com/SharechestDemo/sharechest-logo-2.png",
  };

  return (
    <BannerLayout>
      <BannerLayout.Banner>
        <section className={styles.banner}>
          <div className={styles.bannerContainer}>
            <GenericBanner companyLogoUrl={company.logo}>
              <h1>{content.title ?? "Merci de l'intérêt que vous nous portez!"}</h1>
              <div className="globalSubText">
                {content.body ?? "Nous vous contacterons prochainement pour confirmer vos informations."}
              </div>
            </GenericBanner>
          </div>
          <Curve
            style={{ position: "absolute", bottom: "0", marginBottom: "-1px" }}
          />
        </section>
      </BannerLayout.Banner>

      <BannerLayout.Bottom>
        {routerContext.state.screenIndex == routerContext.state.totalScreenCount - 1 ? (
        <Button onClick={() => {}}>
          <a
            className={styles.a}
            href="https://sharechest.io/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more about Sharechest
          </a>
        </Button>
        ) : (
            <Button onClick={() => { routerContext.goNext(); }}>
              Connexion
            </Button>
        )}
        <br />
        <br />
        <ProgressBar
          currentStep={routerContext.state.screenIndex}
          totalSteps={routerContext.state.totalScreenCount}
        />
      </BannerLayout.Bottom>
    </BannerLayout>
  );
};

interface ThankYouContent {
  title: string;
  body: string;
}

export interface ThankYouProps {
  content: ThankYouContent;
}

export default ThankYouFr;
