import { MutableRefObject, useContext, useEffect, useRef } from "react";
import Layout from "../../Layouts/Layout/index";
import Button from "../../Button";
import ProgressBar from "../../ProgressBar";
import { AreaChart, Area, ResponsiveContainer } from "recharts";
import styles from "./styles.module.scss";
import { RouterContext } from "../../../contexts/routerContext";
import { useState } from "react";
import chartData from "../../../mocks/chartData";
import { SubmissionContext } from "../../../contexts/submissionContext";

const InvestmentInterest: React.FC<any> = (props) => {
  const inputElement = useRef();
  const [orderDisplay, setOrderDisplay] = useState("0");
  const [prevOrderDisplay, setPrevOrderDisplay] = useState("");
  const routerContext = useContext(RouterContext);
  const submissionContext = useContext(SubmissionContext);

  return (
    <Layout>
      <Layout.Top>
        <h1
          className={styles.keyValues}
          style={{ textAlign: "left", marginBottom: "18px" }}
        >
          Apple
        </h1>
      </Layout.Top>
      <Layout.Middle>
        <section className={styles.container}>
          <div className={styles.chart}>
            <ResponsiveContainer width="100%" maxHeight={250}>
              <AreaChart
                height={200}
                data={chartData}
                margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
              >
                {/* <YAxis
                  tickLine={false}
                  domain={["dataMine", "dataMax"]}
                  width={2}
                  mirror
                  tickFormatter={(x) => `$${x}`}
                  orientation="right"
                  minTickGap={8}
                /> */}
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="10%" stopColor="#7871F4" stopOpacity={1} />
                    <stop offset="45%" stopColor="#7871F4" stopOpacity={0.5} />
                    <stop offset="55%" stopColor="#7871F4" stopOpacity={0.3} />
                    <stop offset="75%" stopColor="#7871F4" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <Area
                  type="monotone"
                  dataKey="uv"
                  stroke="#FFF"
                  fill="url(#colorUv)"
                />
              </AreaChart>
            </ResponsiveContainer>
            <div className={styles.accountStats}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "12px",
                  alignItems: "center",
                }}
              >
                {/* <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    textAlign: "left",
                    fontSize: ".8em",
                    marginBottom: "4px",
                  }}
                >
                  <strong className={styles.ticker}>GME</strong>
                  <strong>Up $2.0 (1.0%) past day</strong>
                </div> */}
                <h1>How much would you like to purchase?</h1>
                <div className={styles.inputContainer}>
                  <input
                    className={styles.input}
                    // onFocus={handleFocus}
                    // onBlur={formatAndSave}
                    // onChange={formatAndSave}
                    onChange={(e) => setOrderDisplay(e.target.value)}
                    // onKeyUp={formatAndSave}
                    // ref={inputElement.current}
                    value={orderDisplay}
                  />
                </div>
              </div>
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div>
                  <p>total shares</p>
                  <p className={styles.keyValues}>666</p>
                </div>
                <div>
                  <p>total worth</p>
                  <div style={{ display: "flex", alignItems: "flex-end" }}>
                    <p className={styles.keyValues}>20,000</p>
                    <p style={{ marginBottom: "4px" }}>USD</p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </Layout.Middle>

      <Layout.Bottom>
        <Button
          onClick={() => {
            submissionContext?.updateData({ amount: parseInt(orderDisplay) });
            routerContext.goNext();
          }}
        >
          Next
        </Button>
        <br />
        <br />
        <ProgressBar
          currentStep={routerContext.state.screenIndex}
          totalSteps={routerContext.state.totalScreenCount}
        />
      </Layout.Bottom>
    </Layout>
  );
};

// interface Bar {
//   name: string;
//   uv: number;
//   amt: number;
// }

// interface SharesOwned {
//   title: string;
// }

export interface SharesOwnedProps {}

export default InvestmentInterest;
