import BannerLayout from "../../Layouts/BannerLayout";
import GenericBanner from "../../GenericBanner";
import styles from "./styles.module.scss";


const OrderProcessing: React.FC = () => {
  return (
    <BannerLayout>
      <BannerLayout.Banner className={styles.layoutBanner}>
        <section className={styles.banner}>
          <div className={styles.bannerContainer}>
            <GenericBanner companyLogoUrl={""}>
              <h1 style={{ marginBottom: "24px" }}>
                Hang on just one moment...
              </h1>
              <h1 className={styles.subTitle}>We are processing your order.</h1>
            </GenericBanner>
          </div>

          <img className={styles.image} src="https://sharechest-core.s3-us-east-2.amazonaws.com/Sharechest/sharechest-widget-image.gif" />
          {/* <Curve
            style={{ position: "absolute", bottom: "0", marginBottom: "-1px" }}
          /> */}
        </section>
      </BannerLayout.Banner>

      <BannerLayout.Bottom>
        {/* <Button onClick={routerContext.goNext}>Continue</Button>
      <br />
      <br />
      <ProgressBar
        currentStep={routerContext.state.screenIndex}
        totalSteps={routerContext.state.totalScreenCount}
      /> */}
      </BannerLayout.Bottom>
    </BannerLayout>
  );
};

export default OrderProcessing;
