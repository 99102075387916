import React, { useState } from "react";

export interface CompanyIdContextInterface {
    companyId: string | null;
    setCompanyId: (companyId: string) => void;
}

export const CompanyContext = React.createContext<CompanyIdContextInterface | null>(null);

interface CompanyProviderProps {
    children: any;
    initialCompanyId: string | null;
}

export const CompanyProvider: React.FC<CompanyProviderProps> = (props) => {
    // Get the initial companyId from the global variable
    const { children, initialCompanyId } = props;

    const [companyId, setCompanyId] = useState<string | null>(initialCompanyId);

    const value = {
        companyId,
        setCompanyId,
    };

    return <CompanyContext.Provider value={value}>{children}</CompanyContext.Provider>;
};

export default {
    CompanyProvider,
};
