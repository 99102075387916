const chartData = [
  {
    name: "Page A",
    uv: 2800,
    amt: 2400,
  },
  {
    name: "Page B",
    uv: 3500,
    amt: 2210,
  },
  {
    name: "Page C",
    uv: 2800,
    amt: 2290,
  },
  {
    name: "Page D",
    uv: 4000,
    amt: 2000,
  },
  {
    name: "Page E",
    uv: 3300,
    amt: 2181,
  },
  {
    name: "Page F",
    uv: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    uv: 3000,
    amt: 2100,
  },
  {
    name: "Page G",
    uv: 4000,
    amt: 2100,
  },
  {
    name: "Page G",
    uv: 3200,
    amt: 2100,
  },
  {
    name: "Page G",
    uv: 2000,
    amt: 2100,
  },
];

export default chartData;
