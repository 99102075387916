import BannerLayout from "../../Layouts/BannerLayout";
import ImageBanner from "../../ImageBanner";
import Button from "../../Button";
import ProgressBar from "../../ProgressBar";
import Curve from "../../Curve";
import styles from "./styles.module.scss";
import {useContext} from "react";
import {RouterContext} from "../../../contexts/routerContext";

const Welcome: React.FC<WelcomeProps> = (props) => {
  const { content } = props;
  const routerContext = useContext(RouterContext);
  console.log('content', content);

  return (
    <BannerLayout>
      <BannerLayout.Banner className={styles.banner}>
        <div className={styles.bannerContainer}>
          <ImageBanner
            welcomeMessage={content.welcomeMessage}
            bgImageUrl={content.welcomeImageUrl}
          />
        </div>
        <Curve
          style={{ position: "absolute", bottom: "0", marginBottom: "-1px" }}
        />
      </BannerLayout.Banner>

      <BannerLayout.Bottom>
        <Button onClick={() => { routerContext.goNext(); }}>
          {content.buttonText || "Let's connect"}
        </Button>
        <br />
        <br />
        <ProgressBar
          currentStep={routerContext.state.screenIndex}
          totalSteps={routerContext.state.totalScreenCount}
          // currentStep={$router.screenIndex}
          // totalSteps={$router.totalScreenCount}
        />
      </BannerLayout.Bottom>
    </BannerLayout>
  );
};

interface WelcomeContent {
  welcomeMessage: string;
  buttonText: string;
  welcomeImageUrl: string;
}

export interface WelcomeProps {
  content: WelcomeContent;
}

export default Welcome;
