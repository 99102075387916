import React from "react";
import ReactDOM from "react-dom";
import "./global.css";
import App from "./App";
import { ConnectorProvider } from "./contexts/connectorContext";
import { RouterProvider } from "./contexts/routerContext";
import resolve from "./components/screens/resolver";
import { SubmissionProvider } from "./contexts/submissionContext";
import { UserProvider } from "./contexts/userContext";
import { CompanyProvider} from "./contexts/companyContext";

window.__sharechest_app = (targetDomId, companyId, hostName, justShowScreens) =>
  ReactDOM.render(
    <React.StrictMode>
      <UserProvider initialUser={{ id: '', name: '', tag: '' }} companyid={companyId}>
        <CompanyProvider initialCompanyId={companyId}>
            <ConnectorProvider initialConnector={null}>
              <SubmissionProvider>
                <RouterProvider screens={[]} componentResolver={resolve}>
                  <App
                    target={document.getElementById(targetDomId)}
                    companyId={companyId}
                    hostName={hostName}
                    justShowScreens={justShowScreens}
                  />
                </RouterProvider>
              </SubmissionProvider>
            </ConnectorProvider>
        </CompanyProvider>
      </UserProvider>
    </React.StrictMode>,
    document.getElementById("widget-stake-trunk")
  );
