import Layout from "../../Layouts/Layout/index";
import Checkmark from "../../icons/Checkmark/index";
import Button from "../../Button/index";
import ProgressBar from "../../ProgressBar/index";
import styles from "./styles.module.scss";
import { useContext } from "react";
import { RouterContext } from "../../../contexts/routerContext";

const Overview: React.FC<OverviewProps> = (props) => {
  const routerContext = useContext(RouterContext);
  const { content, bookAMeetingContent } = props;

  return (
    <Layout>
      <Layout.Top>
        <section>
          <h1 className={styles.title}>{content.title}</h1>
          <div className={`globalSubText ${styles.subText}`}>
            {content.body}
          </div>
          <br />
        </section>
      </Layout.Top>

      <Layout.Middle>
        <section slot="middle" className={styles.centered}>
          <div className={styles.items}>
            {content?.steps?.map((item, i) => {
              return (
                <>
                  <div className={styles.item}>
                    <Checkmark
                      style={{ marginRight: "14px", minWidth: "24px" }}
                      width={24}
                    />
                    <div className={styles.text}>{item}</div>
                  </div>
                  {i != content.steps.length - 1 && (
                    <div className={styles.divider} />
                  )}
                </>
              );
            })}
          </div>
          <div className={`globalSubText ${styles.moreInfo}`}>
            <br />
            {bookAMeetingContent && (
              <>
                <a
                  href={bookAMeetingContent?.buttonLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here to book a meeting with our team!
                </a>
                <br />
                <br />
              </>
            )}
            <a href={content.faqLink} target="_blank" rel="noopener noreferrer">
              {content.faqMessage}
            </a>
          </div>
        </section>
      </Layout.Middle>

      <Layout.Bottom>
        <Button onClick={routerContext.goNext}>Continue</Button>
        <br />
        <br />
        <ProgressBar
          currentStep={routerContext.state.screenIndex}
          totalSteps={routerContext.state.totalScreenCount}
        />
      </Layout.Bottom>
    </Layout>
  );
};

interface OverviewContent {
  title: string;
  body: string;
  faqLink: string;
  faqMessage: string;
  steps: string[];
}

interface BookAMeetingContent {
  title: string;
  body: string;
  buttonLink: string;
}

export interface OverviewProps {
  content: OverviewContent;
  bookAMeetingContent: BookAMeetingContent;
}

export default Overview;
