const Checkmark = ({ width = 28, height = 28, style }: CheckmarkProps) => {
  return (
    <svg width={width} height={height} style={{...style}} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.9998 0.666504C6.63984 0.666504 0.666504 6.63984 0.666504 13.9998C0.666504 21.3598 6.63984 27.3332 13.9998 27.3332C21.3598 27.3332 27.3332 21.3598 27.3332 13.9998C27.3332 6.63984 21.3598 0.666504 13.9998 0.666504ZM13.9996 24.6665C8.11962 24.6665 3.33296 19.8798 3.33296 13.9998C3.33296 8.11983 8.11962 3.33317 13.9996 3.33317C19.8796 3.33317 24.6663 8.11983 24.6663 13.9998C24.6663 19.8798 19.8796 24.6665 13.9996 24.6665ZM11.3334 16.8931L20.1201 8.10645L22.0001 9.99978L11.3334 20.6664L6.00005 15.3331L7.88005 13.4531L11.3334 16.8931Z" fill="var(--sharechest-launcher-clr-primary)"/>
    </svg>
  );
}

interface CheckmarkProps {
  width?: number;
  height?: number;
  style?: object;
}

export default Checkmark;