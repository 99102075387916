import * as React from "react";
import { ScreenTypes } from "../components/screens/resolver";

export interface ConnectorContextInterface {
  connector: Connector;
  setConnector: (connector: Connector) => {};
  validate: (connector: Connector) => void;
  setCssColors: (connector: Connector) => void;
  getFinalSubmissionScreen: (connector: Connector) => void;
}

export const ConnectorContext = React.createContext<ConnectorContextInterface | null>(
  null
);

interface ConnectorProviderProps {
  children: any;
  initialConnector: Connector;
}

const SHARECHEST_BRAND_COLOR = "#7871f4";

export const ConnectorProvider: React.FC<ConnectorProviderProps> = (props) => {
  const [connector, setConnector] = React.useState<Connector | undefined>(
    undefined
  );

  const validate = (connector: Connector) => {
    if (connector == null) throw new Error("Missing connector details");
    if (connector.company_name == null) throw new Error("Connector is missing company details");
    // TODO: more validations?
  };

  const setCssColors = (connector: Connector) => {
    const parseBrandColor = () => {
      if (connector.company_name == null) return SHARECHEST_BRAND_COLOR;
      if ( connector.brandColor == null || connector.brandColor.length === 0 ) return SHARECHEST_BRAND_COLOR;
      return connector.brandColor;
    };

    const brandColor = parseBrandColor();
    //(document as any)?.getElementById("widget-stake-trunk").style.setProperty("--sharechest-launcher-clr-primary", brandColor);
    overrideCssVariable(brandColor);
  };

  const overrideCssVariable = (color: string = SHARECHEST_BRAND_COLOR) => {
    // Create a new <style> tag
    const styleElement: any = document.createElement('style');
    styleElement.type = 'text/css';

    // Set the CSS content to override the original value
    const cssContent = `
        .sc_connector_react * {
            --sharechest-launcher-clr-primary: ${color};
        }
    `;

    // Add the CSS content to the <style> tag
    if (styleElement.styleSheet) {
      // For IE compatibility
      styleElement.styleSheet.cssText = cssContent;
    } else {
      styleElement.appendChild(document.createTextNode(cssContent));
    }

    // Append the <style> tag to the document's <head>, so it will override previous styles
    document.head.appendChild(styleElement);
  }

  const getFinalSubmissionScreen = (connector: Connector) => {
    const unelgibleScreens = [ScreenTypes.ThankYou];

    const elgibleScreens = connector.connector_screens.filter((x) => {
      var name = x.screen_type as ScreenTypes;
      return !unelgibleScreens.includes(name);
    });

    const val = elgibleScreens.sort(
      (a, b) => a.screen_sequence_number - b.screen_sequence_number
    )[elgibleScreens.length - 1]?.screen_sequence_number;

    return val;
  };

  const value: any = {
    connector,
    setConnector,
    validate,
    setCssColors,
    getFinalSubmissionScreen,
  };

  return (
    <ConnectorContext.Provider value={value}>
      {props.children}
    </ConnectorContext.Provider>
  );
};

export default {
  ConnectorProvider,
};
