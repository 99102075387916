import Footer from "../../../components/Footer";
import "./styles.scss";

const BannerLayout: React.FC & BannerLayoutComposition = (props: any) => {
  return (
    <div id="layout_wrap">
      {props.children}

      <div id="layout_footer">
        <slot name="footer">
          <Footer />
        </slot>
      </div>
    </div>
  )
}

const Banner = (props: SectionProps) => {
  return (
    <div id="layout_banner" className={props.className}>
      {props.children}
    </div>
  );
}

const Bottom = (props: SectionProps) => {
  return (
    <div id="layout_bottom" className={props.className}>
      {props.children}
    </div>
  );
}

BannerLayout.Banner = Banner;
BannerLayout.Bottom = Bottom;

interface SectionProps {
  children: any;
  className?: string;
}

interface BannerLayoutComposition {
  Banner: React.FC<SectionProps>;
  Bottom: React.FC<SectionProps>;
}

export default BannerLayout;