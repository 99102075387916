import { mock } from "./mocks/connector";

export const getConnectorForCompany = async (
  companyId: string,
  hostName: string
): Promise<Connector> => {
   const url = process.env.REACT_APP_BACKEND_URL+`/connector/company/${companyId}?hostname=${hostName}`;
  //const url = `http://localhost:5000/connector/company/${companyId}?hostName=${hostName}`;
  //const url = `http://localhost:8000/connector/company/24c1994d-6fa3-490b-8ee9-466498b9d3dd?hostname=sharechest.io`; //standard
  //const url = `http://localhost:8000/connector/company/e4b879d3-b845-40e6-802f-6dadfbb74f41?hostname=sharechest.io`; //connector+
  //const url = `localhost:8000/connector/company/dc054c2e-fbab-421d-bfb8-88058904aff6?hostname=sharechest.io`; //free connector


  //window.location.host

  return await fetch(url)
    .then<ConnectorDTO>((x) => x.json())
    .then(connectorFromDto);
};

const connectorFromDto = (dto: ConnectorDTO): Connector => {
  /*const screens = dto.connector_screens.map((screen) => {
    const data = screen.screen_data;
    const newData = Object.keys(data).reduce((prev: any, current: any) => {
      const newKey = `${current.slice(0, 1).toLowerCase()}${current.slice(1)}`;
      return {
        ...prev,
        [newKey]: data[current],
      };
    }, {});

    const newScreen: IScreen = {
      ...screen,
      screen_data: newData,
    };

    return newScreen;
  });

  return {
    ...dto,
    connector_screens: screens,
  };*/
  return dto;
};
