import BannerLayout from "../../Layouts/BannerLayout";
import Button from "../../Button";
import ProgressBar from "../../ProgressBar";
import GenericBanner from "../../GenericBanner";
import styles from "./styles.module.scss";
import { useContext } from "react";
import { RouterContext } from "../../../contexts/routerContext";
import { SubmissionContext } from "../../../contexts/submissionContext";
import { UserContext } from "../../../contexts/userContext";
import { getConnector } from "../../../Connector"

const UpdatedThankYou: React.FC<ThankYouProps> = (props: any) => {
  const routerContext = useContext(RouterContext);
  const userContext = useContext(UserContext);
  const submissionContext = useContext(SubmissionContext);

  const investmentType =
    // @ts-ignore
    submissionContext?.submission?.data?.interestTypes || "Investing";
  const { content, onClose } = props;

  const company = {
    logo:
      "https://sharechest-core.s3-us-east-2.amazonaws.com/SharechestDemo/sharechest-logo-2.png",
  };

  return (
    <BannerLayout>
      <BannerLayout.Banner className={styles.layoutBanner}>
        <section className={styles.banner}>
          <div className={styles.bannerContainer}>
            <GenericBanner companyLogoUrl={""}>
              <h1 style={{ marginBottom: "24px" }}>
                {"Thank you for placing your order"}
              </h1>
              <div className="globalSubText">
                Your order will be displayed on your Alpaca account and an order
                confirmation has been sent to your email.
              </div>
              <div className="globalSubText"></div>
            </GenericBanner>
          </div>

          <img className={styles.image} src="https://sharechest-core.s3-us-east-2.amazonaws.com/Sharechest/sharechest-widget-image.gif" />
          {/* <Curve
            style={{ position: "absolute", bottom: "0", marginBottom: "-1px" }}
          /> */}
        </section>
      </BannerLayout.Banner>

      <BannerLayout.Bottom>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Button
            onClick={() => {
              onClose();
              getConnector();
              routerContext.goToIndex(routerContext.state, 0);
            }}
          >
            Close
          </Button>
          <a
            style={{ width: "100%" }}
            href={process.env.REACT_APP_DASHBOARD_URL}
            target="_blank"
          >
            <Button
              style={{ marginLeft: "3%" }}
              onClick={() => {}}
              type="ghost"
            >
              Go To Dashboard
            </Button>
          </a>
        </div>
        <br />
        <br />
        <ProgressBar
          currentStep={routerContext.state.screenIndex}
          totalSteps={routerContext.state.totalScreenCount}
        />
      </BannerLayout.Bottom>
    </BannerLayout>
  );
};

interface ThankYouContent {
  title: string;
  subTitle: string;
  buttonText: string;
}

export interface ThankYouProps {
  content: ThankYouContent;
}

export default UpdatedThankYou;
