import Welcome from "./Welcome";
import CompanyMessage from "./CompanyMessage";
import CompanyMessageFr from "./CompanyMessageFr";
import Interest from "./Interest";
import InterestFr from "./InterestFr";
import Overview from "./Overview";
import Amount from "./Amount";
import AmountFr from "./AmountFr";
import ContactInformation from "./ContactInformation";
import ContactInformationFr from "./ContactInformationFr";
import ThankYou from "./ThankYou";
import ThankYouFr from "./ThankYouFr";
import UpdatedThankYou from "./UpdatedThankYou";
import Login from "./Login";
import Welcoming from "./Welcoming";
import SharesOwned from "./SharesOwned";
import OrderSummary from "./OrderSummary";
import Confirmation from "./Confirmation";
import InvestmentInterest from "./InvestmentInterest";
import OrderPreview from "./OrderPreview";


export enum ScreenTypes {
  Welcome = "Welcome",
  CompanyMessage = "CompanyMessage",
  Overview = "Overview",
  InterestType = "Securities",
  // SecurityType = "Securities",
  Amount = "Amount",
  Contact = "Contact",
  ThankYou = "ThankYou",
  UpdatedThankYou = "UpdatedThankYou",
  BookAMeeting = "BookAMeeting",
  Login = "Login",
  Welcoming = "Welcoming",
  SharesOwned = "SharesOwned",
  OrderSummary = "OrderSummary",
  Confirmation = "Confirmation",
  InvestmentInterest = "InvestmentInterest",
  OrderPreview = "OrderPreview",
  InvestmentThankYou = "InvestmentThankYou",
}

const ResolveScreen: React.FC<{
  type: ScreenTypes;
  language: string;
  screenProps: any;
  onClose: any;
}> = ({ type, language, screenProps, onClose }) => {
  switch (type as ScreenTypes) {
    case ScreenTypes.Welcome:
      return <Welcome {...screenProps} />;
    case ScreenTypes.CompanyMessage:
      return language === "EN"? <CompanyMessage {...screenProps} /> : <CompanyMessageFr {...screenProps} />;
    case ScreenTypes.Overview:
      return <Overview {...screenProps} />;
    case ScreenTypes.InterestType:
      return language === "EN"? <Interest {...screenProps} /> : <InterestFr {...screenProps} />;
    case ScreenTypes.Login:
      return <Login {...screenProps} />;
    case ScreenTypes.Welcoming:
      return <Welcoming {...screenProps} />;
    case ScreenTypes.SharesOwned:
      return <SharesOwned {...screenProps} />;
    case ScreenTypes.OrderSummary:
      return <OrderSummary {...screenProps} />;
    case ScreenTypes.InvestmentInterest:
      return <InvestmentInterest {...screenProps} />;
    // case ScreenNames.SecurityType:
    //   return <SecurityType {...screenProps} />;
    case ScreenTypes.Amount:
      return language === "EN"? <Amount {...screenProps} /> : <AmountFr {...screenProps} />;
    case ScreenTypes.Contact:
      return language === "EN"? <ContactInformation {...screenProps} /> : <ContactInformationFr {...screenProps} />;
    case ScreenTypes.Confirmation:
      return <Confirmation {...screenProps} />;
    case ScreenTypes.ThankYou:
      return language === "EN"? <ThankYou {...screenProps} /> : <ThankYouFr {...screenProps} />;
    case ScreenTypes.UpdatedThankYou:
      return <UpdatedThankYou onClose={onClose} {...screenProps} />;
    case ScreenTypes.OrderPreview:
      return <OrderPreview {...screenProps} />;
    // case ScreenNames.BookAMeeting:
    //   return BookAMeeting;
    default:
      return <Welcome {...screenProps} />;
  }
};

export default ResolveScreen;
