import React, { useState } from "react";

export interface User {
  id: string;
  name: string;
  tag: string;
}

export interface UserContextInterface {
  user: User;
  setUser: (user: User) => void;
  companyId: string | null;
  setCompanyId: (companyId: string | null) => void;
  token: string;
  setToken: (token: string) => void;
  logOut: () => void;
}

export const UserContext = React.createContext<UserContextInterface | null>(
  null
);

interface UserProviderProps {
  children: any;
  initialUser: User;
  companyid: string | null;
}

export const UserProvider: React.FC<UserProviderProps> = (props) => {
  const { children, initialUser, companyid } = props;
  const [user, setUser] = useState<User>(initialUser);

  const [companyId, setCompanyId] = useState<string | null>(companyid);

  let [token, setToken] = useState<string>("");

  const logOut = () =>
    setUser({
      id: "",
      name: "",
      tag: "",
    });

  const value: any = {
    user,
    token,
    setToken,
    setUser,
    companyId,
    setCompanyId,
    logOut,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default {
  UserProvider,
};
