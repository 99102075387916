const Curve = (props: CurveProps) => {
  const { style } = props;

  return (
    <svg style={{...style}} viewBox="0 0 706 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0C103.5 72.5 560.764 3.48024 706.5 66H0V0Z" fill="white"/>
    </svg>
  );
}

interface CurveProps {
  style?: object;
}

export default Curve;