declare interface ConnectorSubmissionRequest {
  companyInvestor: Omit<any, "id">;
  data: any[];
}

export const submit = async (
  submission: Submission,
  token: string
) => {
  const url = process.env.REACT_APP_BACKEND_URL+`/submission`;

  const body: ConnectorSubmissionRequest = {
    companyInvestor: submission.investor,
    data: submission.data,
  };

  return await fetch(url, {
    method: "POST",
    headers: new Headers({
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(body),
  }).then((x) => x.json);
};
