import styles from "./styles.module.scss";

const Button = (props: ButtonProps) => {
  const {
    type = "primary",
    onClick,
    className,
    style,
    children,
    disabled,
  } = props;
  return (
    <button
    disabled={disabled}
      onClick={onClick}
      style={style}
      className={`${type ? styles[type] : ""} ${styles.button} ${
        disabled ? styles.disabled : className
      }`}
    >
      {children}
    </button>
  );
};

interface ButtonProps {
  type?: "ghost";
  onClick: any;
  style?: object;
  className?: string;
  children: string | JSX.Element;
  disabled?: boolean;
}

export default Button;
