import Layout from "../../Layouts/Layout/index";
import Button from "../..//Button/index";
import ProgressBar from "../..//ProgressBar/index";
import styles from "./styles.module.scss";
import {useContext, useState} from "react";
import { RouterContext } from "../../../contexts/routerContext";
import { SubmissionContext } from "../../../contexts/submissionContext";
import { ConnectorContext} from "../../../contexts/connectorContext";

const AmountFr: React.FC<AmountProps> = (props) => {
  const routerContext = useContext(RouterContext);
  const submissionContext = useContext(SubmissionContext);
  const connectorContext = useContext(ConnectorContext) as any;
  const { content } = props;

  const [inputValue, setInputValue] = useState(props.content.defaultAmount.toString());

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (/^\d*$/.test(e.target.value)) {
      setInputValue(e.target.value);
    }
  };

  return (
    <Layout>
      <Layout.Top>
        <section className={styles.top}>
          <h1>{content.title || "How much are you interested in spending?"}</h1>
        </section>
      </Layout.Top>

      <Layout.Middle>
        <section className={styles.middle}>
          <br />
          <br />
          <div className={`globalSubText ${styles.disclaimer}`}>
            {content.body ||
              `The amount will only be used for {company.name} to gauge your interest in participating
            in this offering.`}
          </div>
          <br />
          <br />
          <div className={styles.inputContainer}>
            <input className={styles.input} value={inputValue} onChange={handleInputChange} />
            {/* <input
              on:focus={handleFocus}
              on:blur={formatAndSave}
              on:keyup={formatAndSave}
              bind:this={inputElement}
              bind:value={orderDisplay} /> */}
          </div>
        </section>
      </Layout.Middle>

      <Layout.Bottom>
        <Button onClick={() => {
          submissionContext?.updateData({
            submission_name: routerContext.state.screenName,
            submission_data: Number(inputValue),
            submission_type: routerContext.state.screenType,
            submission_screen_id: routerContext.state.screenId,
            submission_company_id: connectorContext?.connector?.company_id,
            submission_connector_id: connectorContext?.connector?.connector_id
          });
          routerContext.goNext();
        }}>Continuer</Button>
        <br />
        <br />
        <ProgressBar
          currentStep={routerContext.state.screenIndex}
          totalSteps={routerContext.state.totalScreenCount}
        />
      </Layout.Bottom>
    </Layout>
  );
};

interface AmountContent {
  defaultAmount: number;
  minimumAmount: number;
  title: string;
  body: string;
}

export interface AmountProps {
  content: AmountContent;
}

export default AmountFr;
