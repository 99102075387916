import React from "react";
import Footer from "../../../components/Footer/index";
import "./styles.scss";

const Layout: React.FC<{ customFooter?: React.FC<any> }> & LayoutComposition = (
  props
) => {
  const { customFooter } = props;
  const Custom = customFooter as React.FC<any>;
  return (
    <div id="layout_wrap">
      {props.children}

      {customFooter !== undefined ? (
        <Custom />
      ) : (
        <div id="layout_footer">
          <Footer />
        </div>
      )}
    </div>
  );
};

const Top = (props: SectionProps) => {
  return (
    <div id="layout_top" className={props.className}>
      {props.children}
    </div>
  );
};

const Middle = (props: SectionProps) => {
  return (
    <div id="layout_middle" className={props.className}>
      {props.children}
    </div>
  );
};

const Bottom = (props: SectionProps) => {
  return (
    <div id="layout_bottom" className={props.className}>
      {props.children}
    </div>
  );
};

Layout.Top = Top;
Layout.Middle = Middle;
Layout.Bottom = Bottom;

interface LayoutComposition {
  Top: React.FC<SectionProps>;
  Middle: React.FC<SectionProps>;
  Bottom: React.FC<SectionProps>;
}

interface SectionProps {
  children: any;
  className?: string;
}

export default Layout;
