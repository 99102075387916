import {useState, useContext, useEffect} from "react";
import Layout from "../../Layouts/Layout/index";
import Button from "../../Button";
import ProgressBar from "../../ProgressBar";
import styles from "./styles.module.scss";
import {RouterContext} from "../../../contexts/routerContext";
import {SubmissionContext} from "../../../contexts/submissionContext";
import OrderProcessing from "../OrderProcessing";
import axios from "axios";
import dayjs from "dayjs";
import {useForm} from "react-hook-form";
import currency from "currency.js";
import {UserContext} from "../../../contexts/userContext";
import {ConnectorContext} from "../../../contexts/connectorContext";
import WarningLogo from "../../../assets/warning";
import {TiArrowSortedUp, TiArrowSortedDown, TiRefresh} from "react-icons/ti";
import {TbExternalLink} from "react-icons/tb";
import {RiErrorWarningLine} from "react-icons/ri";
import {BiBroadcast} from "react-icons/bi";
import { formatter } from "../../../utils/currencyFormatter";
import Swal from 'sweetalert2';


interface Price {
    amount: number;
    currency: string;
}

interface OrderDetails {
    action: "Buy" | "Sell";
    quantity: number;
    orderType: "Limit" | "Market";
    price: Price;
    validUntil: "End of the Day" | "Afternoon";
}

const OrderPreview: React.FC<any> = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [limitPrice, setLimitPrice] = useState("");
    const [quantity, setQuantity] = useState("");
    const [orderType, setOrderType] = useState("MarKet");
    const [showPoweredByAlpaca, setShowPoweredByAlpaca] = useState(false);
    const userContext = useContext(UserContext);
    const routerContext = useContext(RouterContext);
    const submissionContext = useContext(SubmissionContext);
    const connectorContext = useContext(ConnectorContext) as any;
    const [currentView, setCurrentView] = useState<"preview" | "confirmation">(
        "preview"
    );

    // get company_stock_symbol from ConnectorContext
    const currentStockSymbol = connectorContext?.connector?.company_stock_symbol ?? "META";

    //const currentStockSymbol = "AAPL";
    const [isBuy, setIsBuy] = useState(true);
    const [isMounted, setIsMounted] = useState(true);
    const [showProcessing, setShowProcessing] = useState(false);
    const [dashboard, setDashboard] = useState<any>({});
    const [tradingAccount, setTradingAccount] = useState<any>({});
    const [errorMessage, setErrorMessage] = useState<any>(null);
    const [showMarketIsClosedHint, setShowMarketIsClosedHint] = useState(false);
    const [marketIsOpen, setMarketIsOpen] = useState(false);
    const [screenContent, setScreenContent] = useState("tradeScreen");
    const [brokerList, setBrokerList] = useState([]);
    const [brokerSelected, setBrokerSelected] = useState<any>();
    const [dataFrontPreview, setDataFrontPreview] = useState<any>({});
    const token = userContext?.token as string;
    let totalCurrentShares;

    const { onScreen } = props;

    useEffect(() => {
        let isActive = true;

        const loopTradingInfo = () => {
            const interval = setInterval(() => {
                if (!isActive) {
                    clearInterval(interval);
                    return;
                }
                getDashboardAndTradingInfo();
            }, 10000);
            return () => clearInterval(interval);
        }

        if (!userContext?.user?.tag) return;

        if (userContext?.user?.tag !== 'alpaca') {
            getUserBrokerList();
        } else {
            getDashboardAndTradingInfo();
            loopTradingInfo();
        }

        return () => {
            isActive = false;
        };
    }, [userContext?.user?.tag]);

    useEffect(() => {
        let isActive = true;

        const loopTradingInfo = () => {
            const interval = setInterval(() => {
                if (!isActive) {
                    clearInterval(interval);
                    return;
                }
                getDashboardAndTradingInfo();
            }, 10000);
            return () => clearInterval(interval);
        }

        if (brokerSelected) {
            // getBalanceByBroker();
            loopTradingInfo();
        }

        return () => {
            isActive = false;
        };
    }, [brokerSelected]);


    useEffect(() => {
        setIsMounted(true);

        return () => {
            setIsMounted(false);
        };
    }, []);


    /*const getBalanceByBroker = async () => {
        const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/front/balance/${userContext?.user.id}/${brokerSelected.broker_type}/`;
        const url = userContext?.user.tag === "tradier" ? `${baseUrl}?account_id=${brokerSelected?.account_number}` : baseUrl;
        await fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => res.json())
            .then((data) => {
                setBalanceBroker(data);
            });
    }*/

    const {
        register,
        handleSubmit,
        getValues,
        formState,
        watch,
        reset,
    } = useForm();

    const processOrder = async () => {
        const values = getValues();
        const buyOrSell = isBuy ? "buy" : "sell";
        const type = brokerSelected ? brokerSelected.broker_type : "alpaca";

        const marketTypeRequestBody = `{
      "symbol": "${currentStockSymbol}",
      "amount": ${quantity},
      "side": "${buyOrSell}",
      "type": "${type}",
      "orderType": "${orderType.toLowerCase()}",
      "timeInForce": "goodForDay"
      ${userContext?.user.tag === "tradier" ? `, "account_id": "${brokerSelected?.account_number}"` : ""}
    }`;

        const limitTypeRequestBody = `{
      "symbol": "${currentStockSymbol}",
      "amount": ${quantity},
      "side": "${buyOrSell}",
      "type": "${type}",
      "orderType": "${orderType.toLowerCase()}",
      "limit_price": ${values.limitPrice},
      "timeInForce": "${values.goodUntil}"
      ${userContext?.user.tag === "tradier" ? `, "account_id": "${brokerSelected?.account_number}"` : ""}
    }`;
        let body =
            orderType.toLowerCase() === "market"
                ? marketTypeRequestBody
                : limitTypeRequestBody;


        await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/front/order/${userContext?.user.id}/`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                },
                body: body,
            }
        )
            .then(async (res) => {
                const data = await res.json();
                if (data.code) {
                    setShowProcessing(false);
                    setErrorMessage(data.message);
                } else if (data.detail) {
                    setShowProcessing(false);
                    setErrorMessage(data.message);
                } else {
                    routerContext.goNext();
                }
            })
            .catch(({response}) => {
                setShowProcessing(false);
                setErrorMessage(response.data.message);
                console.log(response);
            });
    };


    const getDashboardAndTradingInfo = (account?: any) => {
        if (!isMounted) return;

        setIsLoading(true);
        const baseUrl = `${process.env.REACT_APP_BACKEND_URL}/dashboard/${userContext?.user.id}/trading/${currentStockSymbol}/details`;
        const url = userContext?.user.tag === "tradier" ? `${baseUrl}?account_id=${account? account.account_number : brokerSelected?.account_number}` : baseUrl;
        const dashboard = axios.get(
            url, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((res) => res.data)
            .catch((err) => {
                if (err.message && err.message === 'Invalid token.') {
                    userContext?.logOut();
                }
            });
        // .then(()=>console.log("dashboard",dashboard))

        /*const tradingAccount = axios
          .get(
            `${process.env.REACT_APP_BACKEND_URL}/alpaca/${userContext?.user.id}/trading_account`
          )
          .then((res) => res.data);*/

        Promise.all([dashboard]).then((res) => {
            // console.log(res);

            const [dashboardData, tradingAccountData] = res;
            setDashboard(dashboardData);
            setMarketIsOpen(dashboardData.market_is_open);
            //setTradingAccount(tradingAccountData);
            setIsLoading(false);
        });
    };

    const getOrderInfo = (dashboard: any) => {
        const currentDate = dayjs().format("MM/DD/YY");
        const fundsAvaiable = currency(dashboard.investor_buying_power, {
            symbol: "$",
            separator: ",",
            decimal: ".",
        }).format();
        const cash = currency(dashboard.investor_cash, {
            symbol: "$",
            separator: ",",
            decimal: ".",
        }).format();
        totalCurrentShares = dashboard.investor_shares;

        return [
            {
                text: "",
                value: currentDate,
            },
            {
                text: "Buying Power",
                value: fundsAvaiable,
            },
            {
                text: "Cash",
                value: cash,
            },
            {
                text: "Current Holdings",
                value: totalCurrentShares,
            },
        ];
    };

    const orderTypeMapping: { [key: string]: string } =
        orderType === "Limit"
            ? {
                day: "End of day",
                gtc: "Cancelled",
            }
            : {day: "End of day"};

    const getStockInfo = (dashboard: any) => {
        const bidAsk = `${formatter.format(dashboard["bid_price"])} / ${formatter.format(dashboard["ask_price"])}`;
        const lots = `${dashboard["bid_lot"]} / ${dashboard["ask_lot"]}`;
        const openPrice = formatter.format(dashboard["open_price"]);
        const highLow = `${formatter.format(dashboard["high_price"])} / ${formatter.format(dashboard["low_price"])}`;
        const volume = dashboard["daily_volume"];
        const timestamp = dayjs(dashboard["timestamp"]).format("HH:mm:ss DD/MM/YY");
        const currentPrice = dashboard["current_price"];
        const closedPrice = dashboard["closed_price"];
        const symbol = dashboard["company_symbol"];
        const name = dashboard["company_name"];
        const change = dashboard["change_today"];

        const infoList = [
            {
                text: `$${marketIsOpen ? currentPrice : closedPrice} | -tick`,
                value: `${change?.toFixed(2)}%`,
            },
            {
                text: "Bid / Ask",
                value: bidAsk,
            },
            {
                text: "Lots",
                value: lots,
            },
            {
                text: "Open",
                value: openPrice,
            },
            {
                text: "High / Low",
                value: highLow,
            },
            {
                text: "Volume",
                value: volume,
            },
            {
                text: "As of",
                value: timestamp,
            },
        ];

        return {infoList, name};
    };

    const calculatedEstimatedValue = () => {
        let amount = 0;
        if (userContext?.user.tag !== "alpaca") {
            amount = Number(dataFrontPreview?.cost)
        } else {
            amount = Number(quantity) * (orderType === "Limit" ? limitPrice : dashboard["current_price"]);
        }
        return amount;
    };

    const getFrontOrderPreview = async () => {
        const values = getValues();
        const buyOrSell = isBuy ? "buy" : "sell";

        const marketTypeBody = `{        
            "symbol": "${currentStockSymbol}",
            "amount": "${quantity}",
            "side": "${buyOrSell}",
            "type": "${brokerSelected?.broker_type}",
            "orderType": "${orderType.toLowerCase()}",
            "timeInForce": "goodForDay"
            ${userContext?.user.tag === "tradier" ? `, "account_id": "${brokerSelected?.account_number}"` : ""}
        }`;

        const limitTypeBody = `{        
          "symbol": "${currentStockSymbol}",
          "amount": "${quantity}",
          "side": "${buyOrSell}",
          "type": "${brokerSelected?.broker_type}",
          "orderType": "${orderType.toLowerCase()}",
          "limit_price":"${limitPrice}",
          "timeInForce": "${values.goodUntil}"
          ${userContext?.user.tag === "tradier" ? `, "account_id": "${brokerSelected?.account_number}"` : ""}
        }`;

        const body = orderType.toLowerCase() === "market" ? marketTypeBody : limitTypeBody;

        const url = `${process.env.REACT_APP_BACKEND_URL}/front/order_preview/${userContext?.user?.id}/`;

        await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`,
                },
                body,
            }
        )
            .then(async (res) => {
                const data = await res.json();
                setDataFrontPreview(data);
            })
            .catch((err) => console.log("there is error ", err));


    }

    const isChangeUp =
        Math.sign(Number(dashboard["change_today"])) === -1 ? false : true;

    const clickedBtnStyle = {
        height: "40px",
        maxWidth: "100px",
        borderRadius: "6px",
    };
    const notClickedBtnStyle = {
        height: "40px",
        maxWidth: "100px",
        borderColor: "white",
    };

    const OrderInfoStuff = () => {
        const {infoList, name} = getStockInfo(dashboard);
        return (
            <>
                <div className={styles.companyName}>
                    {dashboard["company_name"]}
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "10%",
                        justifyContent: "space-between",
                    }}
                >
                    <div style={{ display: "flex", flexDirection: "row",}} >
                        <div style={{borderWidth: "7px"}}>
                            {isChangeUp ? (
                                <TiArrowSortedUp size={26} color="green"/>
                            ) : (
                                <TiArrowSortedDown size={26} color="red"/>
                            )}
                        </div>
                        <div className={styles.tickerName}>
                            {dashboard["company_symbol"]}
                        </div>
                    </div>
                    <div>
                        <Button onClick={() => getDashboardAndTradingInfo() } style={{ width: "6rem", height: "100%" }}
                                disabled={isLoading}><span>{isLoading ? "Loading..." : "Refresh"}</span></Button>
                    </div>
                </div>
                {infoList.map((stock) => (
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "24px",
                        }}
                        key={stock.text}
                    >
                        <div className={styles.stockInfoText}>{stock.text}</div>
                        <div style={{fontWeight: 600}}>{stock.value}</div>
                    </div>
                ))}
            </>
        );
    };

    const LockedInRightSide = () => (
        <>
            <div>
                <div
                    className={styles.tickerName}>{dashboard["company_symbol"]}</div>
                <div style={{display: "flex", flexDirection: "row"}}>
                    <div style={{borderWidth: "7px"}}>
                        {isChangeUp ? (
                            <TiArrowSortedUp size={26} color="green"/>
                        ) : (
                            <TiArrowSortedDown size={26} color="red"/>
                        )}
                    </div>
                    <div style={{paddingTop: "5px", fontWeight: "bold"}}>
                        {" "}
                        {isChangeUp ? "UP" : "DOWN"}{" "}
                    </div>
                    <div
                        style={{paddingTop: "5px", marginLeft: "5px", fontWeight: "bold"}}
                    >
                        {Math.abs(Number(dashboard["change_today"])).toFixed(4)}
                    </div>
                    <div
                        style={{paddingTop: "5px", marginLeft: "5px", fontWeight: "bold"}}
                    >
                        {"("}
                        {Math.abs(Number(dashboard["change_today"])).toFixed(2)}
                        {"%)"}
                    </div>
                </div>
            </div>
            <div style={{marginLeft: "25px", marginBottom: "20px"}}>
                as of {dayjs(dashboard["timestamp"]).format("HH:mm:ss DD/MM/YY")}
            </div>
            <div
                style={{
                    borderBottom: "1px solid gray",
                    paddingBottom: "12px",
                    marginBottom: "12px",
                }}
                className={styles.companyName}
            >
                {dashboard["company_name"]}
            </div>
            <div className={styles.totalConfirmedValueTitle}>Total</div>
            <div className={styles.totalConfirmedValue}>
                {userContext?.user.tag === "getfront" ?
                    Object.keys(dataFrontPreview).length === 0 ?
                        'loading...' :
                        currency(Number(dataFrontPreview?.amount * dataFrontPreview?.estimatedUnitPrice), {
                            symbol: "$",
                            separator: ",",
                            decimal: "."
                        }).format()
                    : currency(calculatedEstimatedValue(), {symbol: "$", separator: ",", decimal: "."}).format()}
            </div>

            {errorMessage !== null && (
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        bottom: 0,
                        backgroundColor: "#ecc8cb",
                        padding: "12px",
                        borderRadius: "8px",
                        fontWeight: 500,
                        maxWidth: "300px",
                        margin: "12px auto",
                        textAlign: "center",
                    }}
                >
                    <WarningLogo/>
                    <span style={{marginLeft: "4px"}}>
            {errorMessage || "An error occurred."}
          </span>
                </div>
            )}
        </>
    );

    const getUserBrokerList = async () => {
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/front/broker_list/${userContext?.user?.id}/`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((res) => res.json())
            .then((data) => {
                setBrokerList(data);
                setBrokerSelected(data[0]);
                getDashboardAndTradingInfo(data[0]);
            });
    }

    const CustomFooter = () => <div style={{marginBottom: "32px"}}></div>;

    switch (screenContent) {
        case "tradeScreen":
            return (
                <>
                    {showProcessing ? (
                        <OrderProcessing/>
                    ) : (
                        <Layout customFooter={CustomFooter}>
                            <Layout.Middle>
                                {
                                    <div
                                        style={{
                                            height: "100%",
                                            padding: "48px 48px 12px 48px",
                                            display: "flex",
                                        }}
                                    >
                                        <div
                                            style={{
                                                paddingRight: "12px",
                                                paddingTop: "8px",
                                                borderRight: "1px solid gray",
                                                width: "50%",
                                                height: "100%",
                                                display: "flex",
                                                flexDirection: "column",
                                            }}
                                        >
                                            {userContext?.user.tag !== "alpaca" && (
                                                <div
                                                    style={{
                                                        borderBottom: "1px solid gray",
                                                        paddingBottom: "12px",
                                                    }}
                                                >
                                                    <select
                                                        id="brokerSelect"
                                                        className={styles.inputBroker}
                                                        name="brokerSelect"
                                                        defaultValue={brokerSelected ? brokerSelected.account_number : "Select a Broker"}
                                                        onChange={(e: any) => {
                                                            const selectedBroker = brokerList.find((broker: any) => broker.account_number === e.target.value);
                                                            setBrokerSelected(selectedBroker || null);
                                                        }}
                                                    >
                                                        {!brokerSelected &&
                                                            <option value="Select a Broker">Select a Broker</option>}
                                                        {brokerList.map((broker: any) => (
                                                            <option key={broker.front_user_id}
                                                                    value={broker.account_number}>
                                                                {broker.account_name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            )}
                                            <div
                                                style={{
                                                    borderBottom: "1px solid gray",
                                                    paddingBottom: "12px",
                                                    paddingTop: "12px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "flex-end",
                                                    }}
                                                >
                                                    {" "}
                                                    <a
                                                        href={process.env.REACT_APP_DASHBOARD_URL + "/dashboard?tableName=transactions"}
                                                        className={styles.openOrdersText}
                                                        target="_blank"
                                                    >
                                                        View Transactions
                                                    </a>
                                                </div>
                                                {getOrderInfo(dashboard).map((order) => (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            marginBottom: "7px",
                                                        }}
                                                    >
                                                        <div>{order.text}</div>
                                                        <div style={{fontWeight: 600, minWidth: "105px"}}>
                                                            {order.value}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div style={{margin: "auto 0"}}>
                                                <form>
                                                    <div style={{marginBottom: "24px", marginTop: "12px"}}>
                                                        <div className={styles.inputTitle}>Action</div>
                                                        {currentView === "preview" ? (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    border: "2px solid #7971f3",
                                                                    maxWidth: "200px",
                                                                    borderRadius: "10px",
                                                                }}
                                                            >
                                                                <Button
                                                                    style={
                                                                        isBuy ? clickedBtnStyle : notClickedBtnStyle
                                                                    }
                                                                    type={isBuy ? undefined : "ghost"}
                                                                    onClick={(
                                                                        e: React.MouseEvent<HTMLElement>
                                                                    ) => {
                                                                        e.preventDefault();
                                                                        setIsBuy(true);
                                                                    }}
                                                                >
                                                                    Buy
                                                                </Button>
                                                                <Button
                                                                    type={isBuy ? "ghost" : undefined}
                                                                    style={
                                                                        isBuy ? notClickedBtnStyle : clickedBtnStyle
                                                                    }
                                                                    disabled={totalCurrentShares === 0}
                                                                    onClick={(
                                                                        e: React.MouseEvent<HTMLElement>
                                                                    ) => {
                                                                        e.preventDefault();
                                                                        setIsBuy(false);
                                                                    }}
                                                                >
                                                                    Sell
                                                                </Button>
                                                            </div>
                                                        ) : (
                                                            <div className={styles.confirmedValue}>
                                                                {isBuy ? "Buy" : "Sell"}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div style={{marginBottom: "24px"}}>
                                                        <div className={styles.inputTitle}>Quantity</div>
                                                        {currentView === "preview" ? (
                                                            <>
                                                                <input
                                                                    // {...register("shareQuantity", {
                                                                    //   valueAsNumber: true,
                                                                    // })}
                                                                    // min="0"
                                                                    type="text"
                                                                    className={styles.input}
                                                                    // type="number"
                                                                    placeholder="Quantity"
                                                                    value={quantity ? quantity : ""}
                                                                    onChange={(e: any) => {
                                                                        let num = e.target.value;
                                                                        {
                                                                            orderType === "Limit"
                                                                                ? setQuantity(
                                                                                    e.target.value
                                                                                        ? String(
                                                                                            parseInt(
                                                                                                String(e.target.value)
                                                                                            )
                                                                                        )
                                                                                        : ""
                                                                                )
                                                                                : setQuantity(
                                                                                    num.indexOf(".") >= 0
                                                                                        ? num.substr(
                                                                                            0,
                                                                                            num.indexOf(".")
                                                                                        ) +
                                                                                        num.substr(
                                                                                            num.indexOf("."),
                                                                                            3
                                                                                        )
                                                                                        : num
                                                                                );
                                                                        }
                                                                    }}
                                                                />{" "}
                                                                Shares
                                                            </>
                                                        ) : (
                                                            <div className={styles.confirmedValue}>
                                                                {/* {watch("shareQuantity")} */} {quantity}
                                                            </div>
                                                        )}
                                                    </div>
                                                    <div
                                                        style={{
                                                            marginBottom: "24px",
                                                            display: "flex",
                                                            justifyContent: "space-between",
                                                            maxWidth: "225px",
                                                        }}
                                                    >
                                                        <div style={{marginRight: "12px"}}>
                                                            <div className={styles.inputTitle}>
                                                                Order Type
                                                            </div>
                                                            {currentView === "preview" ? (
                                                                <select
                                                                    id="orderType"
                                                                    // {...register("orderType")}
                                                                    className={styles.input}
                                                                    name="orderType"
                                                                    // defaultValue="Market"
                                                                    defaultValue={
                                                                        orderType ? orderType : "Market"
                                                                    }
                                                                    onChange={(e: any) => {
                                                                        setOrderType(e.target.value);

                                                                        if (orderType === "Market") {
                                                                            setLimitPrice("");
                                                                        }
                                                                        if (orderType === "Limit" || quantity) {
                                                                            setQuantity(String(parseInt(quantity)));
                                                                        } else if (!quantity) {
                                                                            setQuantity("");
                                                                        }
                                                                    }}
                                                                >
                                                                    <option value="Market" key={'market'}>Market
                                                                    </option>
                                                                    <option value="Limit" key={'limit'}>Limit</option>
                                                                </select>
                                                            ) : (
                                                                <div className={styles.confirmedValue}>
                                                                    {orderType}
                                                                </div>
                                                            )}
                                                        </div>
                                                        {orderType === "Limit" && (
                                                            <div>
                                                                <div className={styles.inputTitle}>Price</div>
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    {currentView === "preview" ? (
                                                                        <>
                                                                            <input
                                                                                {...register("limitPrice", {
                                                                                    valueAsNumber: true,
                                                                                })}
                                                                                type="number"
                                                                                placeholder="Price"
                                                                                className={styles.input}
                                                                                value={limitPrice ? limitPrice : ""}
                                                                                onChange={(e: any) => {
                                                                                    let num = e.target.value;
                                                                                    setLimitPrice(
                                                                                        num.indexOf(".") >= 0
                                                                                            ? num.substr(
                                                                                                0,
                                                                                                num.indexOf(".")
                                                                                            ) +
                                                                                            num.substr(
                                                                                                num.indexOf("."),
                                                                                                10
                                                                                            )
                                                                                            : num
                                                                                    );
                                                                                }}
                                                                            />{" "}
                                                                            USD
                                                                        </>
                                                                    ) : (
                                                                        <div className={styles.confirmedValue}>
                                                                            {watch("limitPrice")} USD
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>

                                                    <div style={{marginRight: "12px"}}>
                                                        <div className={styles.inputTitle}>Good Until</div>
                                                        {currentView === "preview" ? (
                                                            <select
                                                                style={{width: "150px"}}
                                                                {...register("goodUntil")}
                                                                className={styles.input}
                                                                name="goodUntil"
                                                                id="goodUntil"
                                                            >
                                                                {Object.keys(orderTypeMapping).map((key) => (
                                                                    <option value={key} key={key}>
                                                                        {orderTypeMapping[key]}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        ) : (
                                                            <div className={styles.confirmedValue}>
                                                                {orderTypeMapping[watch("goodUntil")]}
                                                            </div>
                                                        )}
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                width: "50%",
                                                paddingTop: "8px",
                                                paddingLeft: "16px",
                                            }}
                                        >
                                            {currentView === "preview" ? (
                                                <>
                                                    <OrderInfoStuff/>
                                                    {!marketIsOpen && (
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                display: "flex",
                                                                flexDirection: "row",
                                                            }}
                                                        >
                                                            <BiBroadcast size={"20"} color="orange"/>
                                                            <span
                                                                style={{
                                                                    width: "100%",
                                                                    fontWeight: "bold",
                                                                    marginLeft: "3%",
                                                                    marginTop: "2%",
                                                                    color: "#646363",
                                                                    fontSize: "0.9rem",
                                                                }}
                                                            >
                                USA Markets Closed
                              </span>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <LockedInRightSide/>
                                            )}
                                        </div>
                                    </div>
                                }
                            </Layout.Middle>

                            <Layout.Bottom>
                                <div
                                    style={{
                                        borderTop: "1px solid gray",
                                        paddingTop: "18px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginBottom: "2%",
                                    }}
                                >
                                    <div>
                                        <div
                                            onClick={() => setScreenContent("RegulatoryFees")}
                                            style={{
                                                cursor: "pointer",
                                                display: "flex",
                                                flexDirection: "row",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    marginRight: "6px",
                                                    fontSize: "0.9rem",
                                                    fontWeight: 300,
                                                }}
                                            >
                                                Regulatory fees{" "}
                                            </div>

                                            <RiErrorWarningLine/>
                                        </div>
                                        <div
                                            onClick={() => setScreenContent("PoweredByAlpaca")}
                                            style={{
                                                cursor: "pointer",
                                                display: "flex",
                                                flexDirection: "row",
                                                marginTop: "4%"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    marginRight: "6px",
                                                    fontSize: "0.9rem",
                                                    fontWeight: 300,
                                                }}
                                            >
                                                Powered by Alpaca{" "}
                                            </div>

                                            <RiErrorWarningLine/>
                                        </div>
                                        <div style={{marginTop: "4%"}}>
                                            <a
                                                href={process.env.REACT_APP_DASHBOARD_URL + "/disclosureLinks"}
                                                className={styles.openOrdersText}
                                                target="_blank"
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                    }}
                                                >
                                                    <span style={{fontSize: "0.8rem", color: "blue"}}>
                                                        Alpaca Disclosures <TbExternalLink/>
                                                    </span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <Button
                                        onClick={() => {
                                            if (currentView === "preview") {
                                                reset();
                                                setQuantity("");
                                                setOrderType("Market");
                                                setLimitPrice("");
                                            } else {
                                                setErrorMessage(null);
                                                setCurrentView("preview");
                                            }
                                        }}
                                        type="ghost"
                                        style={{width: "200px", height: "42px"}}
                                    >
                                        {currentView === "preview" ? "Clear" : "Change"}
                                    </Button>
                                    <Button
                                        // disabled={
                                        //   currentView !== "preview" && watch("shareQuantity") === 0
                                        // }
                                        disabled={
                                            (currentView === "preview" && (!quantity || Number(quantity) <= 0)) ||
                                            (currentView === "preview" &&
                                                orderType === "Limit" &&
                                                limitPrice === "")
                                                ? true
                                                : false
                                        }
                                        onClick={() => {
                                            // if the limit price is 0, popup a warning message
                                            if (currentView === "preview") {
                                                if (Number(limitPrice) == 0 && orderType === "Limit") {
                                                    Swal.fire({
                                                        title: "Error!",
                                                        text: "Limit price cannot be 0. Please enter a valid limit price.",
                                                        icon: "error",
                                                        customClass: {
                                                            container: styles.swalZIndex,
                                                        },
                                                    });
                                                    return;
                                                }

                                                if (marketIsOpen) {
                                                    setCurrentView("confirmation");
                                                } else {
                                                    setScreenContent("MarketClosedHint");
                                                }
                                                if (userContext?.user.tag !== "alpaca") getFrontOrderPreview();

                                                // if limitPrice is +/- 30% of current market value, popup a warning message#103
                                                if (limitPrice && dashboard?.current_price) {
                                                    const limitPriceValue = Number(limitPrice);
                                                    const currentPriceValue = Number(dashboard?.current_price);
                                                    const priceDifference = (Math.abs(limitPriceValue - currentPriceValue) / currentPriceValue);
                                                    if (priceDifference > 0.3) {
                                                        const moreOrLess = limitPriceValue > currentPriceValue ? "more" : "less";
                                                        const message = `The order price ${limitPrice} is 30% ${moreOrLess} than the current market price.`;
                                                        Swal.fire({
                                                            title: "Reminder",
                                                            text: message,
                                                            icon: "warning",
                                                            showCancelButton: true,
                                                            confirmButtonColor: "#3085d6",
                                                            cancelButtonColor: "#d33",
                                                            confirmButtonText: "Continue",
                                                            customClass: {
                                                                container: styles.swalZIndex,
                                                            },
                                                        }).then((result) => {
                                                            if (result.dismiss === Swal.DismissReason.cancel) {
                                                                setErrorMessage(null);
                                                                setCurrentView("preview");
                                                            }
                                                        });
                                                    }
                                                }

                                            } else if (
                                                calculatedEstimatedValue() > (dashboard["investor_cash"])
                                            ) {
                                                setErrorMessage("Not enough cash");
                                            } else {
                                                processOrder();
                                                setShowProcessing(true);
                                            }
                                        }}
                                        style={{width: "200px", height: "42px"}}
                                    >
                                        {currentView === "preview"
                                            ? "Preview Order"
                                            : "Agree & Send"}
                                    </Button>
                                </div>
                                <ProgressBar
                                    currentStep={routerContext.state.screenIndex}
                                    totalSteps={routerContext.state.totalScreenCount}
                                />
                            </Layout.Bottom>
                        </Layout>
                    )}
                </>
            );
        case "MarketClosedHint":
            return (
                <>
                    <Layout customFooter={CustomFooter}>
                        <Layout.Middle>
                            {/* <div
                    style={{
                      height: "100%",
                      padding: "48px 48px 12px 48px",
                      display: "flex",
                    }}
                  > */}
                            <div
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <div
                                    style={{
                                        borderColor: "#7871f4",
                                        borderWidth: "2px",
                                        borderStyle: "solid",
                                        marginTop: "20%",
                                        display: "flex",
                                        flexDirection: "column",
                                        padding: "5%",
                                        width: "80%",
                                        alignSelf: "center",
                                        borderRadius: "10px",
                                    }}
                                >
                  <span
                      style={{
                          textAlign: "center",
                          fontWeight: "bold",

                          fontSize: "2rem",
                      }}
                  >
                  The markets are closed for today. Your order will be processed
                  on the next business day.
                  </span>
                                    <span
                                        style={{
                                            textAlign: "center",
                                            marginTop: "10%",
                                            fontSize: "1.5rem",
                                        }}
                                    >
                    Do you want to proceed?
                  </span>
                                </div>
                                {/* </div> */}
                            </div>
                        </Layout.Middle>
                        <Layout.Bottom>
                            <div
                                style={{
                                    borderTop: "1px solid gray",
                                    paddingTop: "18px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginBottom: "2%",
                                }}
                            >
                                <div>
                                    <div
                                        onClick={() => setScreenContent("RegulatoryFees")}
                                        style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            flexDirection: "row",
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginRight: "6px",
                                                fontSize: "0.9rem",
                                                fontWeight: 300,
                                            }}
                                        >
                                            Regulatory fees{" "}
                                        </div>

                                        <RiErrorWarningLine/>
                                    </div>
                                    <div
                                        onClick={() => setScreenContent("PoweredByAlpaca")}
                                        style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: "4%"
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginRight: "6px",
                                                fontSize: "0.9rem",
                                                fontWeight: 300,
                                            }}
                                        >
                                            Powered by Alpaca{" "}
                                        </div>

                                        <RiErrorWarningLine/>
                                    </div>
                                    <div style={{marginTop: "4%"}} >
                                        <a
                                            href="https://dev-investor.sharechest.io/disclosureLinks"
                                            className={styles.openOrdersText}
                                            target="_blank"
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                }}
                                            >
                                                <span style={{fontSize: "0.8rem", color: "blue"}}>
                                                    Alpaca Disclosures <TbExternalLink/>
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <Button
                                    onClick={() => {
                                        setScreenContent("tradeScreen");
                                        setCurrentView("preview");
                                    }}
                                    type="ghost"
                                    style={{width: "200px", height: "42px"}}
                                >
                                    No
                                </Button>
                                <Button
                                    onClick={() => {
                                        setScreenContent("tradeScreen");
                                        setCurrentView("confirmation");
                                    }}
                                    style={{width: "200px", height: "42px"}}
                                >
                                    Yes
                                </Button>
                            </div>
                        </Layout.Bottom>
                    </Layout>
                </>
            );

        case "PoweredByAlpaca":
            return (
                <>
                    <Layout customFooter={CustomFooter}>
                        <Layout.Middle>
                            {/* <div
                      style={{
                        height: "100%",
                        padding: "48px 48px 12px 48px",
                        display: "flex",
                      }}
                    > */}
                            <div
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <div
                                    style={{
                                        borderColor: "#7871f4",
                                        borderWidth: "2px",
                                        borderStyle: "solid",
                                        marginTop: "20%",
                                        display: "flex",
                                        flexDirection: "column",
                                        padding: "5%",
                                        width: "80%",
                                        alignSelf: "center",
                                        borderRadius: "10px",
                                    }}
                                >
                  <span>
                    Brokerage services are provided by Alpaca Securities LLC
                    (“Alpaca”), member FINRA/SIPC, a wholly-owned subsidiary of
                    AlpacaDB, Inc. Technology is offered by Sharechest. This is
                    not an offer, solicitation of an offer, or advice to buy or
                    sell securities, or open a brokerage account in any
                    jurisdiction where Alpaca is not registered (Alpaca is
                    registered only in the United States). All accounts and
                    trade executions are powered by Alpaca&#39;s technology.
                    View Alpaca&#39;s disclosure at
                    <a
                        target={"_blank"}
                        href="https://alpaca.markets/disclosures"
                        rel="noreferrer"
                    >
                      {" "}
                        <span color="#7871F4" style={{fontSize: "1rem"}}>
                        https://alpaca.markets/disclosures
                      </span>
                    </a>
                  </span>
                                </div>
                                {/* </div> */}
                            </div>
                        </Layout.Middle>
                        <Layout.Bottom>
                            <div
                                style={{
                                    borderTop: "1px solid gray",
                                    paddingTop: "18px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginBottom: "2%",
                                }}
                            >
                                <div>
                                    <div
                                        onClick={() => setScreenContent("RegulatoryFees")}
                                        style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            flexDirection: "row",
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginRight: "6px",
                                                fontSize: "0.9rem",
                                                fontWeight: 300,
                                            }}
                                        >
                                            Regulatory fees{" "}
                                        </div>

                                        <RiErrorWarningLine/>
                                    </div>
                                    <div
                                        onClick={() => setScreenContent("PoweredByAlpaca")}
                                        style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: "4%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginRight: "6px",
                                                fontSize: "0.9rem",
                                                fontWeight: 300,
                                            }}
                                        >
                                            Powered by Alpaca{" "}
                                        </div>

                                        <RiErrorWarningLine/>
                                    </div>
                                    <div style={{marginTop: "4%"}}>
                                        <a
                                            href="https://dev-investor.sharechest.io/disclosureLinks"
                                            className={styles.openOrdersText}
                                            target="_blank"
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                }}
                                            >
                                                <span style={{fontSize: "0.8rem", color: "blue"}}>
                                                    Alpaca Disclosures <TbExternalLink/>
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <Button
                                    onClick={() => {
                                        setScreenContent("tradeScreen");
                                        setCurrentView("preview");
                                    }}
                                    type="ghost"
                                    style={{width: "200px", height: "42px"}}
                                >
                                    Okay
                                </Button>
                            </div>
                        </Layout.Bottom>
                    </Layout>
                </>
            );

        case "RegulatoryFees":
            return (
                <>
                    <Layout customFooter={CustomFooter}>
                        <Layout.Middle>
                            <div
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <div
                                    style={{
                                        borderColor: "#7871f4",
                                        borderWidth: "2px",
                                        borderStyle: "solid",
                                        marginTop: "10%",
                                        display: "flex",
                                        flexDirection: "column",
                                        padding: "5%",
                                        width: "80%",
                                        alignSelf: "center",
                                        borderRadius: "10px",
                                    }}
                                >
                  <span>
                    <h1 style={{fontSize: "1.5rem", fontWeight: "bold", marginBottom: "1.5rem"}}>Regulatory Fees</h1>
                    <span style={{ fontSize: "1.1rem", lineHeight: "1.2rem"}}>
                        Regulatory fees are charged by the US Securities & Exchange Commission (SEC) and Financial Industry Regulatory Authority (FINRA) on all sell orders. Alpaca Securities LLC and Sharechest do not benefit financially from these charges, and they are deducted from sales proceeds.

                        <br/><br/>
                        <li>SEC: $8.00 per $1,000,000 of principal (sells only) - this fee is rounded up to the nearest penny.</li><br/>
                        <li>FINRA Trading Activity Fee (TAF): $0.000145 per share (sells only) - this fee is rounded up to the nearest penny and no greater than $7.27.</li>
                    </span>
                  </span>
                                </div>
                            </div>
                        </Layout.Middle>
                        <Layout.Bottom>
                            <div
                                style={{
                                    borderTop: "1px solid gray",
                                    paddingTop: "18px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    marginBottom: "2%",
                                }}
                            >
                                <div>
                                    <div
                                        onClick={() => setScreenContent("RegulatoryFees")}
                                        style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            flexDirection: "row",
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginRight: "6px",
                                                fontSize: "0.9rem",
                                                fontWeight: 300,
                                            }}
                                        >
                                            Regulatory fees{" "}
                                        </div>

                                        <RiErrorWarningLine/>
                                    </div>
                                    <div
                                        onClick={() => setScreenContent("PoweredByAlpaca")}
                                        style={{
                                            cursor: "pointer",
                                            display: "flex",
                                            flexDirection: "row",
                                            marginTop: "4%",
                                        }}
                                    >
                                        <div
                                            style={{
                                                marginRight: "6px",
                                                fontSize: "0.9rem",
                                                fontWeight: 300,
                                            }}
                                        >
                                            Powered by Alpaca{" "}
                                        </div>

                                        <RiErrorWarningLine/>
                                    </div>
                                    <div style={{marginTop: "4%"}}>
                                        <a
                                            href="https://dev-investor.sharechest.io/disclosureLinks"
                                            className={styles.openOrdersText}
                                            target="_blank"
                                        >
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                }}
                                            >
                                                <span style={{fontSize: "0.8rem", color: "blue"}}>
                                                    Alpaca Disclosures <TbExternalLink/>
                                                </span>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                <Button
                                    onClick={() => {
                                        setScreenContent("tradeScreen");
                                        setCurrentView("preview");
                                    }}
                                    type="ghost"
                                    style={{width: "200px", height: "42px"}}
                                >
                                    Okay
                                </Button>
                            </div>
                        </Layout.Bottom>
                    </Layout>
                </>
            );
        default:
            return <></>;
    }
};

export default OrderPreview;
