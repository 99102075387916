import styles from "./styles.module.scss";

const Checkbox = (props: CheckboxProps) => {
  const { checked } = props;

  return (
    <div className={styles.outer} onClick={props.onClick} style={{...props.style}}>
      <div className={`${checked && styles.checked} ${styles.inner}`} />
    </div>
  );
}

interface CheckboxProps {
  onClick?: () => any;
  checked: boolean;
  style?: object;
}

export default Checkbox;