import { RouterContext } from "../../contexts/routerContext";
import { SubmissionContext } from "../../contexts/submissionContext";
import { ConnectorContext } from "../../contexts/connectorContext";
import { UserContext } from "../../contexts/userContext";
import { useContext, useState } from "react";
import BackArrow from "../../components/svgs/BackArrow";
import CloseIcon from "../../components/svgs/CloseIcon";
import { ScreenTypes } from "../../components/screens/resolver";
import styles from "./styles.module.scss";

const Header: React.FC<HeaderProps> = ({ onClose }) => {
    const routerContext = useContext(RouterContext);
    const submissionContext = useContext(SubmissionContext);
    const userContext = useContext(UserContext);
    const connectorContext = useContext(ConnectorContext) as any;

    // get justShowScreens from the connectorContext
    const shouldShowClose = connectorContext?.connector?.justShowScreens;

    const shouldShowBack =
        routerContext.state.screenIndex > 0 &&
        routerContext.state.screenType !== ScreenTypes.ThankYou &&
        routerContext.state.screenType !== ScreenTypes.Login &&
        routerContext.state.screenType !== ScreenTypes.OrderPreview;
    const shouldShowLogout =
        routerContext.state.screenIndex > 0 &&
        routerContext.state.screenType === ScreenTypes.OrderPreview;
    const shouldShowSkip =
        routerContext.state.screenIndex == 0 &&
        routerContext.state.screenType !== ScreenTypes.Login &&
        routerContext.state.screenType !== ScreenTypes.Welcoming &&
        connectorContext?.connector?.company_stock_symbol &&
        connectorContext?.connector?.connector_version === 'Connector+';

    const subData = submissionContext?.getFullSubmission();
    const impactedConnectors = [
        "72e83622-6a69-4745-b1fc-9a49645bd49c",
        "88e9668b-27a0-480f-8aad-585e7eb650d3",
        "52c42487-4b2f-45f6-9295-1eafa8f8e5ce",
        "da0f3846-43ee-404b-a537-dcc5cb81c901",
    ];

    const handleBackClick = () => {
        const hasInterestInInvesting = (submission: any) => typeof submission.submission_data === 'string' && submission.submission_data.includes("I'm interested in investing");
        const hasCrypto = (submission: any) => typeof submission.submission_data === 'string' && submission.submission_data.includes("Crypto");
        const hasFiat = (submission: any) => typeof submission.submission_data === 'string' && submission.submission_data.includes("Fiat");
        const hasFranchise = (submission: any) => typeof submission.submission_data === 'string' && submission.submission_data.includes("Franchise Ownership");
        const hasPartnerProject = (submission: any) => typeof submission.submission_data === 'string' && submission.submission_data.includes("Partner Project");


        if (impactedConnectors.includes(connectorContext?.connector?.connector_id) && routerContext.getScreens()[routerContext.state.screenIndex - 1].screen_type === 'Amount' && subData?.data != undefined && !subData.data.some(hasInterestInInvesting) && !subData.data.some(hasCrypto)) {
            const indexSecurities = (routerContext.getScreens().find(item => item.screen_type === 'Securities') || {}).screen_sequence_number;
            indexSecurities ? routerContext.goToIndex(routerContext.state, indexSecurities) : routerContext.goBack();
        } else if (subData?.data != undefined && subData.data.some(hasCrypto) && routerContext.state.screenIndex === 5) {
            routerContext.goBack();
        } else if (subData?.data != undefined && subData.data.some(hasFiat) && routerContext.state.screenIndex !== 3) {
            routerContext.state.screenIndex < 3 ? routerContext.goBack() : routerContext.goToIndex(routerContext.state,3);
        } else if (subData?.data != undefined && (subData.data.some(hasFranchise) || subData.data.some(hasPartnerProject)) && routerContext.state.screenIndex !== 1) {
            routerContext.goToIndex(routerContext.state,1);
        } else {
            routerContext.goBack();
        }
        routerContext.goBack();
    }

    const handleLogout = () => {
        userContext?.logOut();
        onClose();
    }

    return (
        <div className={styles.container}>
            {shouldShowBack && (
                <div className={styles.backButton} onClick={handleBackClick}>
                    <BackArrow />
                </div>
            )}
            {shouldShowLogout && (
                <div className={styles.skipButton} onClick={handleLogout}>
                    Logout
                </div>
            )}
            {/*shouldShowSkip && (
                <div className={styles.skipButton} onClick={routerContext.goSkip}>Start Trading</div>
            )*/}
            {shouldShowClose && (
                <div
                    className={styles.closeButton}
                    onClick={() => {
                        onClose();
                        setTimeout(() => {
                            if (window.top !== null) {
                                window.top.postMessage(
                                    {
                                        message: "modalClose",
                                        data: {
                                            screenNum: routerContext.state.screenIndex + 1,
                                            totalScreenCount: routerContext.state.totalScreenCount,
                                        },
                                    },
                                    "*"
                                );
                            }
                        }, 500);
                    }}
                >
                    <CloseIcon />
                </div>
            )}
        </div>
    );
};

interface HeaderProps {
    onClose: () => void;
}

export default Header;
