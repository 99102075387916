import Newspaper from "../../../icons/Newspaper/index";
import styles from "./styles.module.scss";

const Contact = (props: ContactProps) => {
  const { photoUrl, name, subtitle, pressReleaseUrl } = props;

  return (
    <div id={styles.wrap}>
      <img className={styles.photoUrl} src={photoUrl} alt={name} />

      <div>
        <b><p>{name}</p></b>
        <i><p>{subtitle}</p></i>
        <div className={styles.pressRelease}>
          <Newspaper />
          {pressReleaseUrl != null && pressReleaseUrl.length > 0 &&
            <a href={pressReleaseUrl} target="_blank" rel="noopener noreferrer">
              <span>Press Release</span>
            </a>
          }
        </div>
      </div>
    </div>
  )
}

export interface ContactProps {
  photoUrl: string;
  name: string;
  subtitle: string;
  pressReleaseUrl: string;
}

export default Contact;