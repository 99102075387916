// import
import BannerLayout from "../../Layouts/BannerLayout";
import Button from "../../Button";
import ProgressBar from "../../ProgressBar";
import GenericBanner from "../../GenericBanner";
import Curve from "../../../components/Curve";
import styles from "./styles.module.scss";
import { useContext } from "react";
import { RouterContext } from "../../../contexts/routerContext";
import { SubmissionContext } from "../../../contexts/submissionContext";
import { UserContext } from "../../../contexts/userContext";

const Welcoming: React.FC<ThankYouProps> = (props) => {
  const routerContext = useContext(RouterContext);
  const userContext = useContext(UserContext);
  const submissionContext = useContext(SubmissionContext);

  const investmentType =
    // @ts-ignore
    submissionContext?.submission?.data?.interestTypes || "Investing";
  const { content } = props;

  const company = {
    logo:
      "https://sharechest-core.s3-us-east-2.amazonaws.com/SharechestDemo/sharechest-logo-2.png",
  };

  return (
    <BannerLayout>
      <BannerLayout.Banner className={styles.layoutBanner}>
        <section className={styles.banner}>
          <div className={styles.bannerContainer}>
            <GenericBanner companyLogoUrl={""}>
              <h1 style={{ marginBottom: "24px" }}>
                {content.title ?? `Welcome ${userContext?.user.name}`}
              </h1>
              <h1 className={styles.subTitle}>Let's start investing!</h1>
              <div className="globalSubText"></div>
            </GenericBanner>
          </div>

          <img className={styles.image} src="https://sharechest-core.s3-us-east-2.amazonaws.com/Sharechest/sharechest-widget-image.gif" />
          {/* <Curve
            style={{ position: "absolute", bottom: "0", marginBottom: "-1px" }}
          /> */}
        </section>
      </BannerLayout.Banner>

      <BannerLayout.Bottom>
        <Button onClick={routerContext.goNext}>
          {content.buttonText || "Continue"}
        </Button>
        <br />
        <br />
        <ProgressBar
          currentStep={routerContext.state.screenIndex}
          totalSteps={routerContext.state.totalScreenCount}
        />
      </BannerLayout.Bottom>
    </BannerLayout>
  );
};

interface ThankYouContent {
  title: string;
  subTitle: string;
  buttonText: string;
}

export interface ThankYouProps {
  content: ThankYouContent;
}

export default Welcoming;
