import { useState, useContext } from "react";

import Layout from "../../Layouts/Layout";
import Button from "../../Button";
import ProgressBar from "../../ProgressBar";
import styles from "./styles.module.scss";
import { formatPrice } from "../../../utils/formatPrice";
import { RouterContext } from "../../../contexts/routerContext";

import ErrorView from "../../ErrorView";

enum OrderConfirmationError {
  NoFunds = "no_funds",
  DuplicateOrder = "duplicate_order",
  BadAsset = "bad_asset",
  InvalidToken = "invalid_token",
  UnhandledError = "unhandled_error",
}

type OrderConfirmationErrorState = null | {
  error: OrderConfirmationError;
  message: string;
};

const Confirmation: React.FC<any> = ({
  showProgressBar = true,
  onContinue,
  type,
  shares,
  averagePrice,
  totalCost,
  time,
  content,
  symbol,
  showContinueButton = true,
}: ConfirmationProps) => {
  const { title } = content;
  const [error, setError] = useState<OrderConfirmationErrorState>(null);
  const routerContext = useContext(RouterContext);

  const onContinueWrapper = async () => {
    try {
      await onContinue();
    } catch (error: any) {
      if (error.error && error.message) {
        setError(error);
      } else {
        setError({ error: OrderConfirmationError.UnhandledError, message: "" });
      }
    }
  };

  if (error) {
    if (error.error === OrderConfirmationError.NoFunds) {
      return (
        <ErrorView
          title="Uh Oh"
          errorMessage="Your account has insufficient funds!"
          resolutionMessage="Follow the link below to add funds to your account."
          buttons={
            <>
              <Button
                onClick={() => window.open("https://alpaca.markets/", "_blank")}
              >
                Continue
              </Button>
              <br />
              <br />
            </>
          }
        />
      );
    }

    if (error.error === OrderConfirmationError.BadAsset) {
      return (
        <ErrorView
          title="Uh Oh"
          errorMessage="The stock symbol is not valid!"
          resolutionMessage="Review details and try again."
          buttons={
            <>
              <Button onClick={() => setError(null)}>Go back</Button>
              <br />
              <br />
            </>
          }
        />
      );
    }

    if (error.error === OrderConfirmationError.DuplicateOrder) {
      return (
        <ErrorView
          title="Uh Oh"
          errorMessage="This is a duplicate order!"
          resolutionMessage="Follow the link below to view your transactions."
          buttons={
            <>
              <Button
                onClick={() => window.open("https://alpaca.markets/", "_blank")}
              >
                Continue
              </Button>
              <br />
              <br />
            </>
          }
        />
      );
    }

    if (
      error.error === OrderConfirmationError.InvalidToken ||
      error.error === OrderConfirmationError.UnhandledError
    ) {
      return (
        <ErrorView
          title="Uh Oh"
          errorMessage="An unexpected error occurred!"
          resolutionMessage="Click the button to try again"
          buttons={
            <>
              <Button onClick={() => onContinueWrapper()}>Try Again</Button>
              <br />
              <br />
            </>
          }
        />
      );
    }
  }

  return (
    <Layout>
      <Layout.Top>
        <h1>{title || "Order Confirmation"}</h1>
      </Layout.Top>

      <Layout.Middle className={styles.middle}>
        <div className={styles.items} style={{ padding: "0px 88px" }}>
          <div>
            Type: <strong className={styles.itemValue}>{type}</strong>
          </div>
          <div>
            Shares: <strong className={styles.itemValue}>{shares}</strong>
          </div>
          <div>
            Average Price:{" "}
            <strong className={styles.itemValue}>
              {formatPrice(averagePrice)}
            </strong>
          </div>
          <div>
            Total Cost:{" "}
            <strong className={styles.itemValue}>
              {formatPrice(totalCost)}
            </strong>
          </div>
          <div>
            Time:{" "}
            <strong className={styles.itemValue}>
              {new Date().toDateString()} at {new Date().toLocaleTimeString()}
              {/*August 17th, 2021 at 11:22 AM ET*/}
            </strong>
          </div>
        </div>
      </Layout.Middle>

      <Layout.Bottom>
        {showContinueButton ? (
          <Button onClick={onContinueWrapper}>Continue</Button>
        ) : null}
        <br />
        <br />
        {showProgressBar ? (
          <ProgressBar
            currentStep={routerContext.state.screenIndex}
            totalSteps={routerContext.state.totalScreenCount}
          />
        ) : null}
      </Layout.Bottom>
    </Layout>
  );
};

interface ConfirmationContent {
  title: string;
}

export interface ConfirmationProps {
  content: ConfirmationContent;
  symbol: string;
  type: string;
  shares: number;
  averagePrice: number;
  totalCost: number;
  time: number; // timestamp
  onContinue: () => void;
  showProgressBar?: boolean;
  showContinueButton?: boolean;
}

export default Confirmation;
