import styles from "./styles.module.scss";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import LogoAndName from "../LogoAndName/index";
import CloseIcon from "../icons/Close";
import {useContext, useEffect, useState} from "react";
import "./styles.css";
import testStyles from "./test.module.css";
import {ConnectorContext} from "../../contexts/connectorContext";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";

const LauncherAndWidget = ({
  headerText,
  buttonText,
  stockSymbol,
  onClick,
  onTradeClick,
  showLauncher,
}: LauncherProps) => {
  let [isLauncherVisible, setIsLauncherVisible] = useState<boolean>(false);
  let [firstLoad, setFirstLoad] = useState(true);
  let [stock, setStock] = useState<any>({});
  let [isPositiveChange, setIsPositiveChange] = useState<boolean>(true);

  //get connector from context
  const connectorContext = useContext(ConnectorContext);
  const connector : any = connectorContext?.connector;

  useEffect(() => {
    let isActive = true;

    //setIsLauncherVisible(true);
    setFirstLoad(false);
    setIsLauncherVisible(true);
    // call the API to fetch stock data
	if(stockSymbol!=null){
		const fetchAndSetStock = async () => {
		  if (!isActive) return;
		  const stockData = await fetchStockData(stockSymbol);
		  setStock(stockData);
		  setIsPositiveChange(stockData.change_price_today >= 0);
		};

		fetchAndSetStock();

		// Set up an interval to fetch stock data every 10 seconds
		const intervalId = setInterval(fetchAndSetStock, 10000);
		// Return a cleanup function to clear the interval when the component is unmounted
		return () => {
		  clearInterval(intervalId);
		  isActive = false;
		};
	}
  }, []);

  const launcher = {
    imageUrl: "",
    headerText: "",
    buttonText: "",
  };


  const fetchStockData = async (ticker: string) => {
    // Replace with your API fetching logic
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/dashboard/ticker/`,{
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ symbol: ticker }),
    });
    const data = await response.json();
    return data;
  };

/*  const fetchAndSetStock = async () => {
    const stockData = await fetchStockData(stockSymbol);
    setStock(stockData);
    setIsPositiveChange(stockData.change_price_today >= 0);
  };*/

  return ( showLauncher ? (

    <div style={{ display: "flex" }}>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={isLauncherVisible ? "launcher" : "ctaWidget"}
          // key={isLauncherVisible}
          in={isLauncherVisible}
          timeout={750}
          classNames="widget"
          // unmountOnExit
        >
          {isLauncherVisible ? (
            <div
              key="launcher"
              className={styles.widgetContainer}
              // in:fly={{ x: 14, delay: 300, duration: 300 }}
              // out:fly={{ x: 14, duration: 300 }}>
              // style={{ visibility: `${isLauncherVisible ? "visible" : "hidden"}` }}
            >
              <div
                className={styles.closeButton}
                onClick={() => {
                  setTimeout(() => {
                    if (window.top !== null)
                      window.top.postMessage({ message: "widgetClose" }, "*");
                  }, 500);
                  setIsLauncherVisible(false);
                }}
              >
                <CloseIcon />
              </div>
              <div className={styles.widget_view}>
				{connector?.connector_launcher.launcher_image_link?
					<a
					  target="_blank"
					  href={connector?.connector_launcher?.launcher_image_link}>
					  
					  <div
						  className={styles.banner}
						  style={{
							backgroundImage:
								`url("${connector?.connector_launcher?.launcher_image_url || "https://sharechest-core.s3-us-east-2.amazonaws.com/Sharechest/sharechest-widget-image.gif"}")`,
						  }}
						/>
					</a>
				  :
					<div
					  className={styles.banner}
					  style={{
						backgroundImage:
							`url("${connector?.connector_launcher?.launcher_image_url || "https://sharechest-core.s3-us-east-2.amazonaws.com/Sharechest/sharechest-widget-image.gif"}")`,
					  }}
					/>
				}
		  
                
				{stockSymbol &&
					<div className={styles.stockInfo}>
					  {stock ? (
						  <div className={styles.scrollingContent}>
							<div className={styles.stockItem}>
							  <span></span>
							  <span className={styles.stockTicker}>{stock?.company_symbol}</span>
							  <span className={styles.stockPrice}>$ {stock?.current_price?.toFixed(2)}</span>
							  <div className={styles.stockChange}>
								{isPositiveChange ? (
									<div className={styles.stockUp}>
									  <BsArrowUpShort className={styles.arrowIcon} />
									  {stock?.change_price_today?.toFixed(2)} ({(stock?.change_today * 100)?.toFixed(2)}%)
									</div>
								) : (
									<div className={styles.stockDown}>
									  <BsArrowDownShort className={styles.arrowIcon} />
									  {stock?.change_price_today?.toFixed(2)} ({(stock?.change_today * 100)?.toFixed(2)}%)
									</div>
								)}
							  </div>
							</div>
							<div className={styles.stockItem}> {/* 复制的股票信息 */}
							  <span></span>
							  <span className={styles.stockTicker}>{stock?.company_symbol}</span>
							  <span className={styles.stockPrice}>$ {stock?.current_price?.toFixed(2)}</span>
							  <div className={styles.stockChange}>
								{isPositiveChange ? (
									<div className={styles.stockUp}>
									  <BsArrowUpShort className={styles.arrowIcon} />
									  {stock?.change_price_today?.toFixed(2)} ({(stock?.change_today * 100)?.toFixed(2)}%)
									</div>
								) : (
									<div className={styles.stockDown}>
									  <BsArrowDownShort className={styles.arrowIcon} />
									  {stock?.change_price_today?.toFixed(2)} ({(stock?.change_today * 100)?.toFixed(2)}%)
									</div>
								)}
							  </div>
							</div>
						  </div>
					  ) : (
						  <span className={styles.loadingText}>Loading stock data...</span>
					  )}
					</div>
				}


                <div className={styles.content}>
                  {connector?.connector_version === 'Connector+' ?
                      <button onClick={onTradeClick} className={styles.continueButton}>
                        {headerText || "I'm curious"}
                      </button>
                  :
                      <h3 className={styles.title}>
                        {headerText || "Ready to simply invest?"}
                      </h3>
                  }
                  <button onClick={onClick} className={`${styles.continueButton} ${styles.contactButton}`}>
                    {buttonText || "I'm curious"}
                  </button>
                  <div className={styles.footer}>
                    Proudly powered by
                    <a
                      href="http://sharechest.io"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <LogoAndName height={16} width={86} />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              key="ctaWidget"
              className={styles.ctaWidget}
              style={{
                visibility: `${firstLoad ? "hidden" : "visible"}`,
              }}
              // in:fly={{ x: 24, delay: 500, duration: 300 }}
              // out:fly={{ x: 24, duration: 300 }}
              onClick={() => {
                if (window.top !== null)
                  window.top.postMessage({ message: "widgetOpen" }, "*");
                setIsLauncherVisible(true);
              }}
            >
              <img
                src={ connector?.connector_launcher?.launcher_mini_logo_url || "https://sharechest-core.s3.us-east-2.amazonaws.com/Sharechest/MiniLogo/free/free_logo.png"}
                alt={ `${connector?.company_name} logo` || "Sharechest logo" }
                className={styles.sharechest_logo}
              />
            </div>
          )}
        </CSSTransition>
        {/* </CSSTransition> */}
      </SwitchTransition>
    </div> ) : null
  );
};

{
  /* <CSSTransition
          in={!isLauncherVisible}
          timeout={360000}
          classNames="ctaWidget"
          style={{ visibility: `${isLauncherVisible ? "hidden" : "visible"}` }}
        > */
}

export interface LauncherProps {
  headerText: string;
  buttonText: string;
  stockSymbol: string;
  onClick: () => void;
  onTradeClick: () => void;
  showLauncher: boolean;
}

export default LauncherAndWidget;
