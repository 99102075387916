import { useState, useEffect, useContext } from "react";
import classNames from "classnames";
import Layout from "../../Layouts/Layout";
import Button from "../../Button";
import ProgressBar from "../../../components/ProgressBar";
import styles from "./styles.module.scss";
import Arrow from "../../../assets/arrow";
import { formatPrice } from "../../../utils/formatPrice";
import { RouterContext } from "../../../contexts/routerContext";

const OrderSummary: React.FC<OrderSummaryProps> = (props) => {
  const routerContext = useContext(RouterContext);
  const sharePrice = props.sharePrice || 1;
  const initialInvestment = props.content.desiredInvestment || 0;

  const [currency] = useState("USD");
  const [desiredInvestment, setDesiredInvestment] = useState(initialInvestment);
  const [numOfShares, setNumOfShares] = useState(0);
  const [subTotal, setSubtotal] = useState(0);

  useEffect(() => {
    const initialNumOfShares = Number(
      (initialInvestment / sharePrice).toFixed(9)
    );
    setNumOfShares(initialNumOfShares);
    setSubtotal(initialNumOfShares * sharePrice);
  }, [sharePrice, initialInvestment]);

  const onInvestmentChange = (newInvestment: any) => {
    const newInvestmentAmount = Number(newInvestment);

    if (Number.isNaN(newInvestmentAmount) || newInvestmentAmount < 0) return;

    const newNumberOfShares = Number(
      (newInvestmentAmount / sharePrice).toFixed(9)
    );

    setDesiredInvestment(newInvestmentAmount);
    setNumOfShares(newNumberOfShares);
    setSubtotal(newNumberOfShares * sharePrice);
  };

  // const onContinue = () => {
  //   const depositAmount = subTotal + fees;
  //   props.onContinue({
  //     sharePrice,
  //     depositAmount,
  //     numOfShares,
  //     subTotal,
  //     currency,
  //   });
  // };

  const fees = 0;

  return (
    <Layout>
      <Layout.Top>
        <h1>Investment Summary</h1>
      </Layout.Top>
      <Layout.Middle>
        <div style={{ padding: "48px 88px 40px 88px" }}>
          <div className={styles.grid}>
            <h2 className={styles.companyName}>Stark Industries</h2>

            <div className={styles.lineItem}>Individual Share Price</div>
            <div className={classNames(styles.lineItem, styles.rightCol)}>
              <div className={styles.lineItemText}>
                <div>{currency}</div>
                <span>{sharePrice}</span>
              </div>

              <div className={styles.divider} style={{ height: "2px" }} />
            </div>

            <div
              className={styles.lineItem}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>Investment</div>
              <div>Subtotal USD</div>
            </div>
            <div>number</div>
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <div
                role="button"
                onClick={() => onInvestmentChange(desiredInvestment - 1)}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Minus />
              </div>
              <input
                className={styles.desiredInvestment}
                type="number"
                value={desiredInvestment}
                onChange={(e) => onInvestmentChange(e.target.value)}
                style={{
                  width: "50%",
                  border: "2px solid #7971F3",
                  outline: "none",
                }}
              />
              <div
                role="button"
                onClick={() => onInvestmentChange(desiredInvestment + 1)}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Cross style={{ cursor: "pointer" }} />
              </div>
            </div> */}

            {/* <div className={styles.lineItem}>Number of Shares</div>
            <div className={classNames(styles.lineItem, styles.rightCol)}>
              {numOfShares}
              <div className={styles.divider} />
            </div> */}

            <div className={styles.lineItem}>Number of Shares</div>
            <div className={classNames(styles.lineItem, styles.rightCol)}>
              <div className={styles.lineItemText}>Approx. 666</div>
              <div className={styles.divider} style={{ height: "3px" }} />
            </div>

            <strong className={styles.lineItem}>Total Investment</strong>
            <div className={classNames(styles.lineItem, styles.rightCol)}>
              <div style={{ fontWeight: 700 }} className={styles.lineItemText}>
                <div>{currency}</div>
                <span>{formatPrice(subTotal + fees)}</span>
              </div>
            </div>
          </div>
        </div>
      </Layout.Middle>

      <Layout.Bottom>
        <Button onClick={routerContext.goNext}>
          <div className={styles.checkoutButton}>
            <div style={{ textAlign: "left" }}>
              USD {formatPrice(subTotal + fees)}
            </div>
            <div
              style={{ display: "flex", gridGap: "16px", alignItems: "center" }}
            >
              <div>Checkout</div>
              <Arrow />
            </div>
          </div>
        </Button>
        <br />
        <br />
        <ProgressBar
          currentStep={routerContext.state.screenIndex}
          totalSteps={routerContext.state.totalScreenCount}
        />
      </Layout.Bottom>
    </Layout>
  );
};

interface OrderSummaryContent {
  title: string;
  desiredInvestment?: number;
}

export interface OrderSummaryProps {
  content: OrderSummaryContent;

  sharePrice: number;
  onContinue: (payload: {
    sharePrice: number;
    depositAmount: number;
    numOfShares: number;
    currency: string;
    subTotal: number;
  }) => void;
  showProgressBar?: boolean;
}

export default OrderSummary;
